import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.games.patterns,
    props.games.loaded,
    (patterns, games) => {
        return {
            loaded: patterns !== null && games,
            patterns,
            games
        }
    }
)