export default {
    loaded: ({agent}) => agent.loaded,
    agent: ({agent}) => agent.agent,
    merchant: ({agent}) => agent.merchant,
    methods: ({agent}) => agent.methods,
    isAuth: ({agent}) => (agent.loaded && typeof agent.agent === 'object'),
    errors: {
        auth: ({agent}) => agent.errors.auth,
        all: ({agent}) => agent.errors
    },
    buttons: {
        login: ({agent}) => agent.buttons.login,
        all: ({agent}) => agent.buttons
    }
}