import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.sliders.buttons.all,
    props.sliders.errors.all,
    props.sliders.sliders,
    props.agent.merchant,
    (buttons, errors, sliders, merchant) => {
        return {
            buttons,
            errors,
            sliders,
            merchant
        }
    }
)