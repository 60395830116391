import {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../selectors/pages/login';
import {login} from '../../../reducers/agent/actions';

export default () => {
    const [state, setState] = useState({username: "", password: ""}),
          [passwordState, setPasswordState] = useState(false),
          {error, button} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <div class="container-xxl">
            <div class="authentication-wrapper authentication-basic container-p-y">
                <div class="authentication-inner">
                    <div class="card">
                        <div class="card-body">
                            <div class="app-brand justify-content-center">
                                <a href="index.html" class="app-brand-link gap-2">
                                    <span class="app-brand-logo demo">
                                        <img src={`${window.location.origin}/assets/img/logotype.svg`} alt="" />
                                    </span>
                                </a>
                            </div>
                            {error && <div class={`alert alert-${error.type}`} role="alert">{error.message}</div>}
                            <form id="formAuthentication" class="mb-3" onSubmit={e => {
                                e.preventDefault();

                                if(button)
                                    return;

                                dispatch(login(state));
                            }}>
                                <div class="mb-3">
                                    <label for="email" class="form-label">Username</label>
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        id="email" 
                                        name="email-username" 
                                        placeholder="Enter your username" 
                                        autofocus=""
                                        onInput={e => setState({...state, username: e.target.value})}
                                        required />
                                </div>
                                <div class="mb-3 form-password-toggle">
                                    <div class="d-flex justify-content-between">
                                        <label class="form-label" for="password">Password</label>
                                    </div>
                                    <div class="input-group input-group-merge">
                                        <input 
                                            type={!passwordState ? "password" : "text"} 
                                            id="password" 
                                            class="form-control" 
                                            name="password" 
                                            placeholder="············" 
                                            aria-describedby="password"
                                            onInput={e => setState({...state, password: e.target.value})}
                                            required />

                                        <span class="input-group-text cursor-pointer" onClick={e => setPasswordState(!passwordState)}><i className={`bx bx-${passwordState ? 'show' : 'hide'}`}></i></span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <button 
                                        class="btn btn-primary d-grid w-100" 
                                        type="submit"
                                        disabled={button}
                                        >{button ? 'Please wait...' : 'Sign in'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}