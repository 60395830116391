import {useState, useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/bonuses/edit';
import {clearErrors, saveBonus, uploadBonusImage, publishMessage} from '../../../../reducers/bonuses/actions';
import {useLocation, useParams} from 'react-router-dom';
import Tags from '../../../components/tags';
import FormUtils from '../../../../utils/form';
import NotFoundPage from '../../../pages/notfound';

import WelcomeFree from './welcome_free';
import WelcomeDeposit from './welcome_deposit';
import DepositBonus from './deposit_bonus';
import MultiplierBonus from './multiplier_bonus';
import FreespinsBonus from './freespins';
import WhatsAppBonus from './whatsapp_bonus';
import PhoneBonus from './phone_bonus'; 

export default () => {
    const fileInputRef = createRef(),
          fileFormRef = createRef();

    const dispatch = useDispatch(),
          location = useLocation(),
          {buttons, errors, games, bonuses, merchant} = useSelector(Selector),
          {bonus_id} = useParams(),
          editable_bonus = bonuses.filter(bonus => bonus.id == bonus_id)[0] || false,
          [tab, setTab] = useState(merchant.settings.language_list[0]),
          [state, setState] = useState({
            games_restrictions: editable_bonus.games_restrictions,
            games_restrictions_list: editable_bonus.games_restrictions_list
          });

    const getBonusContent = () => {
        const content = {};
        for(const language of merchant.settings.language_list)
            content[language] = {
                name: (editable_bonus && editable_bonus.content[language]) ? editable_bonus.content[language].name : null,
                description: (editable_bonus && editable_bonus.content[language]) ? editable_bonus.content[language].description : null
            }   

        return content;
    }

    const [bonusContent, setBonusContent] = useState(getBonusContent());

    useEffect(() => {
        dispatch(clearErrors());
    }, [location.pathname]);

    if(!editable_bonus)
        return <NotFoundPage />

    return (
        <div class="content-wrapper">
                <div class="flex-grow-1 container-p-y container-fluid">
                    <h4 class="py-3 mb-4"><span class="text-muted fw-light">Bonuses /</span> Bonus #{editable_bonus.id}</h4>

                    {(errors && errors.bonuses_edit) && (
                        <div className={`alert alert-${errors.bonuses_edit.type}`} role="alert">{errors.bonuses_edit.message}</div>
                    )}

                    <div class="card mb-4" style={{marginTop: '20px'}}>
                        <div class="card-body">
                            <form
                                encType="multipart/form-data"
                                style={{display: 'none'}}
                                onSubmit={e => {
                                    e.preventDefault();

                                    if(!buttons.bonuses_edit_image)
                                        dispatch(uploadBonusImage(new FormData(e.target), editable_bonus.id));
                                }}>
                                    <input
                                        ref={fileInputRef}
                                        style={{display: 'none'}}
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        name="bonus_image"
                                        onChange={e => {
                                            const file = e.target.files[0],
                                                size = file.size / (2 ** 20);

                                            if(!['image/png', 'image/jpeg'].includes(file.type))
                                                return dispatch(publishMessage({
                                                    key: "bonuses_edit",
                                                    type: 'warning',
                                                    message: 'The image must be in .png or .svg format'
                                                }));

                                            if(size > 1)
                                                return dispatch(publishMessage({
                                                    key: "bonuses_edit",
                                                    type: 'warning',
                                                    message: 'The maximum file size is 1 MB'
                                                }));

                                            if(fileFormRef.current)
                                                fileFormRef.current.click();
                                        }}
                                        hidden />

                                    <input
                                        type="hidden"
                                        hidden
                                        value={editable_bonus.id}
                                        name="bonusId" />

                                    <button type="submit" ref={fileFormRef}></button>
                                </form>
                                <form onSubmit={e => {
                                    e.preventDefault();

                                    if(buttons.bonuses_create)
                                        return;

                                    const data = FormUtils(e);
                                    // console.log(data);
                                    dispatch(saveBonus(data))
                                }}>
                                    <input name="id" value={editable_bonus.id} hidden style={{display: 'none'}} />

                                    <input name="content" value={JSON.stringify(bonusContent)} hidden />

                                    <h6>1. Bonus information</h6>

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Bonus alias</label>
                                        <input type="text" class="form-control" name="alias" defaultValue={editable_bonus.alias} placeholder="Enter bonus alias..." required />
                                        <div id="floatingInputHelp" class="form-text">Give a nickname to your character to make it easier for you to edit it.</div>
                                    </div>

                                    <div className="row">
                                        <div class="col-xl-12">
                                            <div class="nav-align-top mb-4">
                                                <ul class="nav nav-tabs nav-fill" role="tablist">
                                                    {merchant.settings.language_list.map((language, key) => {
                                                        let icon = "error-alt",
                                                            color = "red";

                                                        if(bonusContent[language].name && bonusContent[language].description) {
                                                            icon = "check";
                                                            color = "green";
                                                        }

                                                        return (
                                                            <li class="nav-item" role="presentation">
                                                                <button type="button" class={`nav-link ${tab === language ? 'active': ''}`} role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="false" tabindex="-1" onClick={e => setTab(language)}>
                                                                    <i class={`tf-icons bx bx-${icon} me-1`} style={{color}}></i> 
                                                                    {language}
                                                                </button>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                <div class="tab-content" style={{borderTop: "1px solid #d9dee3"}}>
                                                    <h6>Customize the bonus for each language version</h6>

                                                    <div className={`alert alert-primary`} role="alert">If you do not specify the data in the language version, the bonus will not be displayed.</div>

                                                    <div class="mb-3">
                                                        <label class="form-label" for="basic-default-fullname">Bonus name</label>
                                                        <input type="text" class="form-control" placeholder={`Enter bonus ${tab} name...`} key={tab} defaultValue={bonusContent[tab].name} onInput={e => {
                                                            setBonusContent({
                                                                ...bonusContent,
                                                                [tab]: {
                                                                    ...bonusContent[tab],
                                                                    name: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                                }
                                                            })
                                                        }} required />
                                                        <div id="floatingInputHelp" class="form-text">The name of your bonus</div>
                                                    </div>

                                                    <div class="mb-3">
                                                        <label class="form-label" for="basic-default-fullname">Bonus descriptions</label>
                                                        <textarea id="basic-default-message" class="form-control" placeholder={`Enter bonus ${tab} descriptions...`} key={tab} defaultValue={bonusContent[tab].description} onInput={e => {
                                                            setBonusContent({
                                                                ...bonusContent,
                                                                [tab]: {
                                                                    ...bonusContent[tab],
                                                                    description: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                                }
                                                            })
                                                        }} required></textarea>
                                                        <div id="floatingInputHelp" class="form-text">You can add bonus data to the description by using keywords <code>{`{bonus_freespins} / {bonus_freespins_game} / {bonus_amount} / {bonus_minimal_deposit} / {bonus_maximal_deposit} / {bonus_multiplier} / {bonus_freespins_devider}`}</code></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Bonus Image</label>
                                        <div class="input-group">

                                            <button class="btn btn-outline-success" type="button" id="button-addon1" onClick={e => {
                                                if(buttons.bonuses_edit_image)
                                                    return;

                                                if(fileInputRef.current)
                                                    fileInputRef.current.click();
                                            }}>{buttons.bonuses_edit_image ? 'Loading...' : 'Upload'}</button>
                                            <button class="btn btn-outline-info" type="button" id="button-addon1" onClick={e => {
                                                if(!editable_bonus.image)
                                                    return window.alert('Logotype path not found');

                                                window.open(editable_bonus.image);
                                            }}>Check</button>
                                            <input type="text" class="form-control" placeholder="Path to logotype" value={editable_bonus.image} key={editable_bonus.image} aria-label="Example text with button addon" aria-describedby="button-addon1" readOnly disabled />
                                        </div>
                                        <div id="floatingInputHelp" class="form-text">If there are free spins in your bonus, then the bonus will use a picture of the game in which you give free spins.</div>
                                    </div>


                                    <hr class="my-4 mx-n4"></hr>
                                    <h6>2. Bonus details</h6>
                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Bonus type</label>
                                        <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" disabled value={editable_bonus.bonus_type} data-allow-clear="true" data-select2-id="collapsible-state" name="bonus_type" tabindex="-1" aria-hidden="true" onChange={e => setBonusType(e.target.value)}>
                                            <option value="welcome_free" data-select2-id="9">Welcome Bonus [No Deposit]</option>
                                            <option value="welcome_deposit" data-select2-id="9">Welcome Bonus [Deposit]</option>
                                            <option value="deposit_bonus" data-select2-id="9">Deposit Bonus [Deposit]</option>
                                            <option value="multiplier" data-select2-id="9">Mutliplier Bonus [Deposit]</option>
                                            <option value="freespins" data-select2-id="23">Freespins [Deposit]</option>
                                            <option value="phone_bonus" data-select2-id="23">Phone Validation</option>
                                            <option value="whatsapp_bonus" data-select2-id="23">WhatsApp Validation</option>
                                        </select>
                                        <div id="floatingInputHelp" class="form-text">The type of your bonus</div>
                                    </div>

                                    {/* {bonusType} */}

                                    {editable_bonus.bonus_type === "welcome_free" && <WelcomeFree games={games.filter(game => game.bm)} bonus={editable_bonus} />}
                                    {editable_bonus.bonus_type === "welcome_deposit" && <WelcomeDeposit games={games.filter(game => game.bm)} bonus={editable_bonus} />}
                                    {editable_bonus.bonus_type === "deposit_bonus" && <DepositBonus games={games.filter(game => game.bm)} bonus={editable_bonus} />}
                                    {editable_bonus.bonus_type === "multiplier" && <MultiplierBonus games={games.filter(game => game.bm)} bonus={editable_bonus} />}
                                    {editable_bonus.bonus_type === "freespins" && <FreespinsBonus games={games.filter(game => game.bm)} bonus={editable_bonus} />}
                                    {editable_bonus.bonus_type === "whatsapp_bonus" && <WhatsAppBonus games={games.filter(game => game.bm)} bonus={editable_bonus} />}
                                    {editable_bonus.bonus_type === "phone_bonus" && <PhoneBonus games={games.filter(game => game.bm)} bonus={editable_bonus} />}

                                    <hr class="my-4 mx-n4"></hr>
                                    <h6>3. Wager condition</h6>

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Bonus mode</label>
                                        <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" defaultValue={editable_bonus.mode} data-allow-clear="true" data-select2-id="collapsible-state" name="mode" tabindex="-1" aria-hidden="true">
                                            <option value="bonus" data-select2-id="9">Bonus</option>
                                            <option value="fun" data-select2-id="23">Free Play</option>
                                        </select>
                                        <div id="floatingInputHelp" class="form-text">The mode of your bonus</div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                            <label class="form-label" for="basic-default-fullname">Wagering mode</label>
                                            <select id="collapsible-state" name="wager_type" class="select2 form-select select2-hidden-accessible" defaultValue={editable_bonus.wager_type} data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                                                <option value="wager" data-select2-id="wager">Wager</option>
                                                <option value="limit_usd" data-select2-id="limit_usd">Redeem (Fixed)</option>
                                                <option value="limit" data-select2-id="limit">Redeem (Multiplier)</option>
                                            </select>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                            <label class="form-label" for="basic-default-fullname">The value of wagering</label>
                                            <input type="number" name="wager_value" defaultValue={editable_bonus.wager_value} class="form-control" placeholder="..." required />
                                        </div>
                                    </div>

                                    <hr class="my-4 mx-n4"></hr>
                                    <h6>4. Games restrictions</h6>

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Games restrictions</label>
                                        <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="games_restrictions" defaultValue={editable_bonus.games_restrictions} tabindex="-1" aria-hidden="true" onChange={e => setState({...state, games_restrictions: parseInt(e.target.value)})}>
                                            <option value="0" data-select2-id="9">The bonus can be wagered in all games</option>
                                            <option value="1" data-select2-id="9">The bonus can be wagered in certain games</option>
                                        </select>
                                    </div>

                                    {state.games_restrictions === 1 && (
                                        <div class="mb-3">
                                            <label class="form-label" for="basic-default-fullname">Wagerings games list</label>
                                            <input name="games_restrictions_list" value={JSON.stringify(state.games_restrictions_list)} hidden />
                                            <Tags
                                                dataKey="games_restrictions_list"
                                                whitelist={games.map(game => `${game.name} // ${game.gameId}`)}
                                                enforceWhitelist={true}
                                                autoComplete={true}
                                                onChange={e => {
                                                    console.log(e);

                                                    e = e.map(name => {
                                                        const game = games.filter(game => game.name === name.split(' // ')[0])[0] || false;
                                                        return game ? game.gameId : null;
                                                    }).filter(g => g);

                                                    setState({
                                                        ...state,
                                                        games_restrictions_list: e
                                                    })
                                                }}
                                                value={state.games_restrictions_list.map(gameId => {
                                                    const game = games.filter(game => game.gameId === gameId)[0] || false;
                                                    return game ? `${game.name} // ${game.gameId}` : null;
                                                }).filter(g => g)} />
                                            <div id="floatingInputHelp" class="form-text">Select the list of games where the bonus wagering will be available</div>
                                        </div>
                                    )}

                                    <hr class="my-4 mx-n4"></hr>
                                    <h6>5. Bonus access</h6>

                                    {!['whatsapp_bonus', 'phone_bonus'].includes(editable_bonus.bonus_type) && (
                                        <>
                                            <div class="mb-3">
                                                <label class="form-label" for="basic-default-fullname">Mandatory phone number validation</label>
                                                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" defaultValue={editable_bonus.phone_validation} data-allow-clear="true" data-select2-id="collapsible-state" name="phone_validation" tabindex="-1" aria-hidden="true">
                                                    <option value="0" data-select2-id="23">No</option>
                                                    <option value="1" data-select2-id="9">Yes</option>
                                                    
                                                </select>
                                                <div id="floatingInputHelp" class="form-text">Does the user have to verify the phone number to activate this bonus</div>
                                            </div>

                                            <div class="mb-3">
                                                <label class="form-label" for="basic-default-fullname">Mandatory WhatsApp validation</label>
                                                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" defaultValue={editable_bonus.whatsapp_validation} data-allow-clear="true" data-select2-id="collapsible-state" name="whatsapp_validation" tabindex="-1" aria-hidden="true">
                                                    <option value="0" data-select2-id="23">No</option>
                                                    <option value="1" data-select2-id="9">Yes</option>
                                                </select>
                                                <div id="floatingInputHelp" class="form-text">Does the user have to connect WhatsApp to activate this bonus</div>
                                            </div>
                                        </>
                                    )}

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Language</label>
                                        <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" disabled defaultValue={editable_bonus.language} data-allow-clear="true" data-select2-id="collapsible-state" name="language" tabindex="-1" aria-hidden="true">
                                            <option value="RU" data-select2-id="23">RU</option>
                                        </select>
                                        <div id="floatingInputHelp" class="form-text">Select the language in which the created bonus will be available</div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Currency</label>
                                        <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" disabled defaultValue={editable_bonus.currency} data-allow-clear="true" data-select2-id="collapsible-state" name="currency" tabindex="-1" aria-hidden="true">
                                            <option value="RUB" data-select2-id="23">RUB</option>
                                            <option value="USD" data-select2-id="9">USD</option>
                                            <option value="EUR" data-select2-id="23">EUR</option>
                                            <option value="PHP" data-select2-id="9">PHP</option>
                                        </select>
                                        <div id="floatingInputHelp" class="form-text">Select the currency in which the created bonus will be available</div>
                                    </div>

                                    <button type="submit" class="btn btn-primary" disabled={buttons.bonuses_edit}>Save</button>
                                </form>
                            </div>
                        </div>
                </div>
            </div>
    )
}