import {useState, useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/vouchers';
import {clearErrors} from '../../../../reducers/agent/actions';
import {useLocation} from 'react-router-dom';
import Currency from './currency';

export default () => {
    const dispatch = useDispatch(),
          location = useLocation(),
          {buttons, errors, vouchers, merchant} = useSelector(Selector),
          [tab, setTab] = useState(merchant.settings.currencys_list[0]);

    useEffect(() => {
        dispatch(clearErrors());
    }, [tab, location.pathname]);

    return (
        <div class="content-wrapper">
            <div class="flex-grow-1 container-p-y container-fluid">
                <h4 class="py-3 mb-4"><span class="text-muted fw-light">Vouchers /</span> List of vouchers</h4>
                <div className="row">
                    <div class="col-xl-12">
                        <div class="nav-align-top mb-4">
                            <ul class="nav nav-tabs nav-fill" role="tablist">
                                {merchant.settings.currencys_list.map((cur, key) => {
                                    return (
                                        <li class="nav-item" role="presentation">
                                            <button type="button" class={`nav-link ${tab === cur ? 'active': ''}`} role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="false" tabindex="-1" onClick={e => setTab(cur)}>
                                                <i class="tf-icons bx bx-wallet me-1"></i> 
                                                {cur}
                                            </button>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div class="tab-content" style={{padding: 0}}>
                                <Currency merchant={merchant} bonuses={vouchers.filter(voucher => voucher.currency === tab)} buttons={buttons} errors={errors} currency={tab} />
                            </div>
                        </div>
                    </div>
                </div>                   
            </div>
        </div>
    )
}