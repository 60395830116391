import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.vouchers.buttons.all,
    props.vouchers.errors.all,
    props.vouchers.vouchers,
    props.agent.merchant,
    (buttons, errors, vouchers, merchant) => {
        return {
            buttons,
            errors,
            vouchers,
            merchant
        }
    }
)