import {useState} from 'react';

export default ({games, bonus}) => {
    const [preset, setPreset] = useState({
        freespins_enabled: bonus.freespins_enabled,
        freespins_game_type: bonus.freespins_game_type,
        bonus_amount_type: bonus.bonus_amount_type,
        freespins_type: bonus.freespins_type || "fixed"
    });

    return (
        <>
            <div class="mb-3">
                <label class="form-label" for="basic-default-fullname">Deposit Include</label>
                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" disabled data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                    <option value="1" data-select2-id="9">Yes</option>
                </select>
                <input name="deposit_include" value={1} readOnly hidden />
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                    <label class="form-label" for="basic-default-fullname">Minimal deposit</label>
                    <input type="number" name="minimal_deposit" defaultValue={bonus.minimal_deposit} class="form-control" placeholder="..." required />
                    <div id="floatingInputHelp" class="form-text">The minimum deposit amount to activate the bonus (<code>{`{minimal_deposit}`}</code>)</div>
                </div>

                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                    <label class="form-label" for="basic-default-fullname">Maximal deposit</label>
                    <input type="number" name="maximal_deposit" defaultValue={bonus.maximal_deposit} class="form-control" placeholder="..." required />
                    <div id="floatingInputHelp" class="form-text">The maximum deposit amount to activate the bonus (<code>{`{maximal_deposit}`}</code>)</div>
                </div>
            </div>

            <div class="mb-3">
                <label class="form-label" for="basic-default-fullname">Bonus amount type</label>
                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" defaultValue={bonus.bonus_amount_type} data-allow-clear="true" data-select2-id="collapsible-state" value={"multiplier"} name="bonus_amount_type" tabindex="-1" aria-hidden="true">
                    <option value="multiplier" data-select2-id="9">Deposit multiplier</option>
                </select>
            </div>            

            <div class="mb-3">
                <label class="form-label" for="basic-default-fullname">Multiplier value</label>
                <input type="number" class="form-control" name="bonus_amount" defaultValue={bonus.bonus_amount} placeholder="Enter bonus amount..." required />
                <div id="floatingInputHelp" class="form-text">The number by which the player's deposit will be multiplied (<code>{`{bonus_multiplier}`}</code>)</div>
            </div>

            <div class="mb-3">
                <label class="form-label" for="basic-default-fullname">Bonus freespins</label>
                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" value={0} name="freespins_enabled" tabindex="-1" aria-hidden="true" onChange={e => setPreset({...preset, freespins_enabled: parseInt(e.target.value)})}>
                    <option value="0" data-select2-id="9">Disabled</option>
                </select>
            </div>

        </>
    )
}