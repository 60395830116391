import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {toggleAttribute, fastChangeAttribute} from '../../../reducers/games/actions';
import DataTable from '../../components/datatable';
import FormUtils from '../../../utils/form';

const calculate_rtp = (bets, wins) => {
    if(bets == 0 || wins == 0)
        return 0;

    return parseFloat(((wins/bets)*100).toFixed(2));
}

export default ({merchant, errors, buttons, currency, patterns}) => {
    const dispatch = useDispatch(),
          navigate = useNavigate();

    return (
        <>
            <div className="tab-content" style={{padding: 0}}>
                {!merchant.settings.currencys_list.includes(currency) && <div class="alert alert-info" role="alert">Currency <b>{currency}</b> is not enabled in <b>General settings</b></div>}

                {(errors && errors.patterns) && (
                    <div className={`alert alert-${errors.patterns.type}`} role="alert">{errors.patterns.message}</div>
                )}
            </div>

            <DataTable
                title={'List of games'}
                show={[10, 25, 50, 100, 'All']}
                defaultSort={{column: 'index', type: 'asc'}}
                searchFilter={entity => {
                    let provider = 'undefined';
                    if(entity.gameId[0] === 'P') provider = 'TBS2A';
                    if(entity.gameId[0] === 'K') provider = '2WP';
                    if(entity.gameId[0] === 'T') provider = 'TOM';

                    return {
                        id: entity.id,
                        name: entity.name,
                        provider,
                        gameId: entity.gameId,
                        category: entity.category,
                        gameprovider: entity.provider,
                        wagering: entity.wagering ? 'WAGERING' : '',
                        new: entity.new ? 'NEW' : '',
                        enabled: entity.enabled ? 'ENABLED' : 'DISABLED',
                    }
                }}
                columns={[
                    {
                        column: 'Game ID', 
                        column_key: 'gameId',
                        sortable: false,
                        value: entity => `${entity.id}`
                    },
                    {
                        column: 'Game',
                        column_key: 'game',
                        sortable: false,
                        value: entity => {
                            return (
                                <>
                                    <div class="avatar me-2" style={{float: 'left', height: 'auto'}}>
                                        <img src={entity.image} alt="Game Image" style={{
                                            height: "auto"
                                        }} /> 
                                    </div>
                                    <span style={{fontWeight: '600'}}>
                                        {entity.name}
                                        {entity.gameId[0] === 'P' && <span class="badge bg-label-success" style={{marginLeft: '5px'}}>{entity.gameId}</span>}
                                        {entity.gameId[0] === 'K' && <span class="badge bg-label-primary" style={{marginLeft: '5px'}}>{entity.gameId}</span>}
                                        {entity.gameId[0] === 'T' && <span class="badge bg-label-info" style={{marginLeft: '5px'}}>{entity.gameId}</span>}
                                    </span>
                                </>
                            )
                        }
                    },
                    {
                        column: 'Provider', 
                        column_key: 'provider',
                        sortable: false,
                        value: entity => {
                            if(entity.gameId[0] === 'P') return <span class="badge bg-label-success">TBS2A</span>
                            if(entity.gameId[0] === 'K') return <span class="badge bg-label-primary">2WP</span>
                            if(entity.gameId[0] === 'T') return <span class="badge bg-label-info">TOM</span>
                            return <span class="badge bg-label-success">{entity.gameId}</span>
                        }
                    },
                    {
                        column: 'Picks', 
                        column_key: 'picks',
                        sortable: true,
                        sortable_value: entity => entity.picks,
                        value: entity => entity.picks
                    },
                    {
                        column: 'RTP [M]', 
                        column_key: 'rtp_main',
                        sortable: true,
                        sortable_value: entity => calculate_rtp(entity.bets.main, entity.wins.main),
                        value: entity => calculate_rtp(entity.bets.main, entity.wins.main) + '%',
                    },
                    {
                        column: 'RTP [C]', 
                        column_key: 'rtp_cashback',
                        sortable: true,
                        sortable_value: entity => calculate_rtp(entity.bets.cashback, entity.wins.cashback),
                        value: entity => calculate_rtp(entity.bets.cashback, entity.wins.cashback) + '%',
                    },
                    {
                        column: 'RTP [B]', 
                        column_key: 'rtp_bonus',
                        sortable: true,
                        sortable_value: entity => calculate_rtp(entity.bets.bonus, entity.wins.bonus),
                        value: entity => calculate_rtp(entity.bets.bonus, entity.wins.bonus) + '%',
                    },
                    {
                        column: 'RTP [F]', 
                        column_key: 'rtp_fun',
                        sortable: true,
                        sortable_value: entity => calculate_rtp(entity.bets.fun, entity.wins.fun),
                        value: entity => calculate_rtp(entity.bets.fun, entity.wins.fun) + '%',
                    },
                    {
                        column: 'Status', 
                        column_key: 'status',
                        sortable: true,
                        sortable_value: entity => entity.enabled ? 1 : 0,
                        value: entity => {
                        if(!entity.enabled) return <span class="badge bg-label-danger">DISABLED</span>;
                        return <span class="badge bg-label-success">ENABLED</span>;
                        },
                    },
                    {
                        column: 'Tags', 
                        column_key: 'tags',
                        sortable: true,
                        sortable_value: entity => {
                            if(!entity.wagering && !entity.new) return 0;
                            if(entity.wagering && entity.new) return 11;
                            return 10;
                        },
                        value: entity => {
                            return (
                                <>
                                    {entity.wagering && <span class="badge bg-label-info" style={{marginLeft: '5px'}}>WAGERING</span>}
                                    {entity.new && <span class="badge bg-label-success" style={{marginLeft: '5px'}}>NEW</span>}
                                    <span class="badge bg-label-primary" style={{marginLeft: '5px'}}>{entity.provider}</span>
                                    <span class="badge bg-label-secondary" style={{marginLeft: '5px'}}>{entity.category}</span>
                                </>
                            )
                        },
                    },
                    {
                        column: 'Actions',
                        column_key: 'actions',
                        value: entity => (
                            <div class="dropdown">
                                <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown"><i class="bx bx-dots-vertical-rounded"></i></button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="" onClick={e => {
                                        e.preventDefault();

                                        if(entity.disabled)
                                            return;

                                        dispatch(toggleAttribute('enabled', entity.gameId, currency));
                                    }}>
                                        {entity.enabled ? (
                                            <>
                                                <i class="bx bx-stop me-1"></i> 
                                                Disable game
                                            </>
                                        ) : (
                                            <>
                                                <i class="bx bx-play me-1"></i> 
                                                Enable game
                                            </>
                                        )}
                                    </a>

                                    <a class="dropdown-item" href="" onClick={e => {
                                        e.preventDefault();

                                        if(entity.disabled)
                                            return;

                                        dispatch(toggleAttribute('new', entity.gameId, currency));
                                    }}>
                                        {entity.new ? (
                                            <>
                                                <i class="bx bxs-folder-minus me-1"></i> 
                                                Unmark as NEW
                                            </>
                                        ) : (
                                            <>
                                                <i class="bx bxs-folder-plus me-1"></i> 
                                                Mark as NEW
                                            </>
                                        )}
                                    </a>

                                    <a class="dropdown-item" href="" onClick={e => {
                                        e.preventDefault();

                                        if(entity.disabled)
                                            return;

                                        dispatch(toggleAttribute('wagering', entity.gameId, currency));
                                    }}>
                                        {entity.wagering ? (
                                            <>
                                                <i class="bx bx-stop me-1"></i> 
                                                Disable wagering
                                            </>
                                        ) : (
                                            <>
                                                <i class="bx bxs-gift me-1"></i> 
                                                Enable wagering
                                            </>
                                        )}
                                    </a>
                                </div>
                            </div>
                        )
                    }
                ]}
                entities={patterns} />  

                <div className="card" style={{marginTop: "15px"}}>
                    <div class="card-body">
                        <h6>Quick changes</h6>

                        {(errors && errors.fast_change) && (
                            <div className={`alert alert-${errors.fast_change.type}`} role="alert">{errors.fast_change.message}</div>
                        )}

                        <form onSubmit={e => {
                            e.preventDefault();

                            if(buttons.fast_change)
                                return;

                            const data = FormUtils(e);
                            dispatch(fastChangeAttribute(data))
                        }}>
                            <input type="hidden" hidden readOnly name="preference" value={currency} />

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Attribute</label>
                                    <select id="collapsible-state" name="attribute" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                                        <option value="new" data-select2-id="9">New</option>
                                        <option value="wagering" data-select2-id="9">Wagering</option>
                                        <option value="enabled" data-select2-id="9">Enabled</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">Select the variable you want to change on the provider group</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Value</label>
                                    <select id="collapsible-state" name="value" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                                        <option value="1" data-select2-id="9">True</option>
                                        <option value="0" data-select2-id="9">False</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">Select the value of the atrribute</div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label class="form-label" for="basic-default-fullname">Provider</label>
                                <select id="collapsible-state" name="provider" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                                    <option value="2wp" data-select2-id="9">2WP</option>
                                    <option value="tbs2a" data-select2-id="9">TBS2A</option>
                                    <option value="tom" data-select2-id="9">TOM</option>
                                </select>
                                <div id="floatingInputHelp" class="form-text">Select the provider to change the attribute</div>
                            </div>

                            <button type="submit" class="btn btn-primary" disabled={buttons.fast_change}>Change</button>
                        </form>
                    </div>
                </div>
        </>
    )
}