import types from './types';

const InitialState = {
    loaded: false,
    bonuses: [],
    errors: {
        bonuses_create: null,
        bonuses_list: null,
        bonuses_edit: null
    },
    buttons: {
        bonuses_create: false,
        bonuses_list: false,
        bonuses_edit: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.toggleBonus:
            return {
                ...state,
                bonuses: state.bonuses.map(b => {
                    return b.id === payload ? {
                        ...b,
                        disabled: !b.disabled
                    } : b;
                })
            }
        case types.reindex:
            return {
                ...state,
                bonuses: state.bonuses.map(b => {
                    return {
                        ...b,
                        index: payload[b.id.toString()] || 0
                    }
                })
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                bonuses: payload.bonuses.map(b => {
                    return {
                        ...b,
                        disabled: false
                    }
                })
            }
        case types.remove:
            return {
                ...state,
                bonuses: state.bonuses.filter(bonus => bonus.id !== payload)
            }
        case types.add:
            return {
                ...state,
                bonuses: [
                    ...state.bonuses,
                    {
                        ...payload,
                        disabled: false
                    }
                ]
            }
        case types.update:
            return {
                ...state,
                bonuses: state.bonuses.map(bonus => {
                    return bonus.id === payload.id ? {
                        ...bonus,
                        ...payload
                    } : bonus;
                })
            }
        case types.error_remove:
            return {
                ...state,
                errors: InitialState.errors
            }
        case types.error:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [payload.key]: {
                        type: payload.type,
                        message: payload.message
                    }
                }
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        default:
            return state;
    }
}