import {createSelector} from 'reselect';
import props from './props';

export default createSelector(
    props.agent.merchant,
    (merchant) => {
        return {
            merchant
        }
    }
)