import General from './general';
import Bonuses from './bonuses';
import Jackpots from './jackpots';
import Winners from './winners';
import Referrals from './referrals';
import Finance from './finance';
import Titles from './titles';
import Cashback from './cashback';

export default {
    General,
    Bonuses,
    Jackpots,
    Winners,
    Referrals,
    Finance,
    Titles,
    Cashback
}