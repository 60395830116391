import {useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/settings/merchant';
import {saveBonusesSettings} from '../../../../reducers/agent/actions';
import Tagify from '@yaireo/tagify';
import FormUtils from '../../../../utils/form';

export default ({currency}) => {
    const hoursInput = createRef();

    useEffect(() => {
        if(hoursInput.current)
            new Tagify(hoursInput.current, {
                whitelist: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                userInput: true
            });
    }, [currency]);

    const {merchant, errors, buttons} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <>
            {!merchant.settings.currencys_list.includes(currency) && <div class="alert alert-info" role="alert">Currency <b>{currency}</b> is not enabled in <b>General settings</b></div>}

            {(errors && errors.settings_bonuses) && (
                <div className={`alert alert-${errors.settings_bonuses.type}`} role="alert">{errors.settings_bonuses.message}</div>
            )}
            <div class="card-body">
                <form onSubmit={e => {
                    e.preventDefault();

                    if(buttons.settings_bonuses)
                        return;

                    const data = FormUtils(e);
                    dispatch(saveBonusesSettings(data))
                }}>
                    <input type="hidden" hidden readOnly name="preference" value={currency} />

                    <h6>1. Bonus session</h6>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">The amount to close the bonus</label>
                            <input type="number" name="bonus_remove" defaultValue={merchant.settings.currencys[currency].bonus_remove} class="form-control" placeholder="0.1" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">When this amount is reached, the player's bonus session will automatically close</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">[S] Bonus hall close</label>
                            <input type="number" name="bonus_session" defaultValue={merchant.settings.currencys[currency].bonus_session} class="form-control" placeholder="0.1" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">When this amount is reached, the player will be transferred to the main account</div>
                        </div>
                    </div>

                    <hr class="my-4 mx-n4"></hr>

                    <h6>2. FreePlays</h6>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Activity Days</label>
                            <input type="number" name="fun_days" defaultValue={merchant.settings.currencys[currency].fun_days} class="form-control" placeholder="3" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">The number of days to determine the user's activity</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Activity deposits</label>
                            <input type="number" name="fun_deposits" defaultValue={merchant.settings.currencys[currency].fun_deposits} class="form-control" placeholder="3" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">The amount of deposits in the currency to determine the user's activity</div>
                        </div>

                        <div class="col-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Rewards for completing a free play session</label>
                            <input type="number" name="fun_redeem" defaultValue={merchant.settings.currencys[currency].fun_redeem} class="form-control" placeholder="50" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">The player will receive this amount after completing the freeplay session</div>
                        </div>
                    </div>

                    <button type="submit" class="btn btn-primary" disabled={buttons.settings_bonuses}>Save</button>
                </form>
            </div>
        </>
    )
}