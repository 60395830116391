import types from './types';
import API from '../../lib/api';

export const loadGames = () => async dispatch => {
    const response = await API({
        path: '/games/get'
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: response
        });
}

export const loadPatterns = () => async dispatch => {
    const response = await API({
        path: '/games/patterns'
    });

    console.log(response);

    if(response.success)
        dispatch({
            type: types.patterns,
            payload: response
        });
}

export const toggleAttribute = (attribute, gameId, currency) => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "patterns"
    });

    dispatch({
        type: types.toggleGame,
        payload: {
            gameId,
            currency
        }
    });

    const response = await API({
        path: '/games/toggleAttribute',
        body: {
            attribute,
            gameId,
            currency
        }
    });

    dispatch({
        type: types.toggleGame,
        payload: {
            gameId,
            currency
        }
    });
    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "patterns",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.update,
            payload: response.pattern
        });

        dispatch({
            type: types.error,
            payload: {
                key: "patterns",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const fastChangeAttribute = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "fast_change"
    });

    dispatch({
        type: types.toggleButton,
        payload: "fast_change"
    });

    const response = await API({
        path: '/games/fastChangeAttribute',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: "fast_change"
    });

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "fast_change",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.updates,
            payload: response.patterns
        });

        dispatch({
            type: types.error,
            payload: {
                key: "fast_change",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

