export default {
    loaded: 'sliders.loaded',
    add: 'sliders.add',
    remove: 'sliders.remove',
    update: 'sliders.update',
    reindex: 'sliders.reindex',
    error: "sliders.error",
    error_remove: "sliders.error.remove",
    toggleButton: "sliders.toggle.button",
    toggleSlider: "sliders.toggle.slider"
}