import {useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/settings/merchant';
import {saveTitlesSettings} from '../../../../reducers/agent/actions';
import FormUtils from '../../../../utils/form';

export default ({language}) => {
    const namesTextarea = createRef(),
          gamesTextarea = createRef();

    const {merchant, errors, buttons} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <>
            {(errors && errors.settings_titles) && (
                <div className={`alert alert-${errors.settings_titles.type}`} role="alert">{errors.settings_titles.message}</div>
            )}

            <div class="card-body">
                <form onSubmit={e => {
                    e.preventDefault();

                    if(buttons.settings_titles)
                        return;

                    const data = FormUtils(e);
                    dispatch(saveTitlesSettings(data))
                }}>
                    <input type="hidden" hidden readOnly name="preference" value={language} />


                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Format</label>
                        <input class="form-control" name="format" placeholder="Enter format..." defaultValue={merchant.settings.titles[language].format} key={language} required />
                        <div id="floatingInputHelp" class="form-text">Make your own title display format <code>{`{sitename}`}</code> / <code>{`{title}`}</code></div>
                    </div>

                    <hr class="my-4 mx-n4"></hr>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Home page</label>
                        <input class="form-control" name="main" placeholder="Enter home page title..." defaultValue={merchant.settings.titles[language].main} key={language} required />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Promotions page</label>
                        <input class="form-control" name="bonuses" placeholder="Enter promotions page title..." defaultValue={merchant.settings.titles[language].bonuses} key={language} required />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Profile page</label>
                        <input class="form-control" name="profile" placeholder="Enter profile page title..." defaultValue={merchant.settings.titles[language].profile} key={language} required />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Profile transactions</label>
                        <input class="form-control" name="transactions" placeholder="Enter profile transactions page title..." defaultValue={merchant.settings.titles[language].transactions} key={language} required />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Profile bonuses</label>
                        <input class="form-control" name="my_bonuses" placeholder="Enter profile bonuses page title..." defaultValue={merchant.settings.titles[language].my_bonuses} key={language} required />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Profile referrals</label>
                        <input class="form-control" name="referal" placeholder="Enter profile referrals page title..." defaultValue={merchant.settings.titles[language].referal} key={language} required />
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">FAQ</label>
                        <input class="form-control" name="faq" placeholder="Enter FAQ page title..." defaultValue={merchant.settings.titles[language].faq} key={language} required />
                    </div>

                    <button type="submit" class="btn btn-primary" disabled={buttons.settings_titles}>Save</button>
                </form>
            </div>
        </>
    )
}