import types from './types';

const InitialState = {
    loaded: false,
    errors: {
        settings_general: null,
        settings_bonuses: null,
        settings_jackpots: null,
        settings_referrals: null,
        settings_winners: null,
        settings_finance: null,
        settings_titles: null,
        settings_cashback: null,
    },
    agent: null,
    merchant: null,
    methods: [],
    buttons: {
        settings_general: false,
        settings_bonuses: false,
        settings_jackpots: false,
        settings_referrals: false,
        settings_winners: false,
        settings_finance: false,
        settings_titles: false,
        settings_cashback: false,
        settings_cashback_image: false,
        settings_cashback_image_hh: false,
        login: false,
        logotype: false
    }
} 

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.merchant_settings:
            return {
                ...state,
                merchant: {
                    ...state.merchant,
                    settings: payload
                }
            }
        case types.logout:
            return {
                ...state,
                loaded: true
            }
        case types.error_remove:
            return {
                ...state,
                errors: InitialState.errors
            }
        case types.error:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [payload.key]: {
                        type: payload.type,
                        message: payload.message
                    }
                }
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                agent: payload.agent,
                merchant: payload.merchant,
                methods: payload.methods
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        default:
            return state;
    }
}