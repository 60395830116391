import types from './types';

const InitialState = {
    loaded: false,
    games: [],
    patterns: null,
    errors: {
        patterns: null,
        fast_change: null
    },
    buttons: {
        patterns: false,
        fast_change: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.loaded:
            return {
                ...state,
                loaded: true,
                games: payload.games
            }
        case types.patterns:
            return {
                ...state,
                patterns: payload.patterns.map(pattern => {
                    return {
                        ...pattern,
                        disabled: false
                    }
                })
            }
        case types.updates:
            return {
                ...state,
                patterns: state.patterns.map(pattern => {
                    const has_update = payload.filter(p => p.gameId == pattern.gameId && p.currency == pattern.currency)[0] || false;

                    return has_update ? {
                        ...has_update,
                        disabled: false
                    } : pattern
                })
            }
        case types.update:
            return {
                ...state,
                patterns: state.patterns.map(pattern => {
                    return (pattern.gameId == payload.gameId && pattern.currency == payload.currency) ? {...payload, disabled: false} : pattern;
                })
            }
        case types.toggleGame:
            return {
                ...state,
                patterns: state.patterns.map(pattern => {
                    return (pattern.gameId == payload.gameId) && (pattern.currency === payload.currency) ? {
                        ...pattern,
                        disabled: !pattern.disabled
                    } : pattern
                })
            }
        case types.error_remove:
            return {
                ...state,
                errors: InitialState.errors
            }
        case types.error:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [payload.key]: {
                        type: payload.type,
                        message: payload.message
                    }
                }
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        default:
            return state;
    }
}