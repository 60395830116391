import {useState, useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/bonuses/create';
import {clearErrors, createBonus, publishMessage} from '../../../../reducers/bonuses/actions';
import {useLocation} from 'react-router-dom';
import Tags from '../../../components/tags';
import FormUtils from '../../../../utils/form';

import WelcomeFree from './welcome_free';
import WelcomeDeposit from './welcome_deposit';
import DepositBonus from './deposit_bonus';
import MultiplierBonus from './multiplier_bonus';
import FreespinsBonus from './freespins';
import WhatsAppBonus from './whatsapp_bonus';
import PhoneBonus from './phone_bonus';

export default () => {
    const dispatch = useDispatch(),
          location = useLocation(),
          {buttons, errors, games, merchant} = useSelector(Selector),
          [bonusType, setBonusType] = useState('welcome_free'),
          [tab, setTab] = useState(merchant.settings.language_list[0]),
          [state, setState] = useState({
            games_restrictions: 0,
            games_restrictions_list: []
          });

    const getBonusContent = () => {
        const content = {};
        for(const language of merchant.settings.language_list)
            content[language] = {
                name: null,
                description: null
            }   

        return content;
    }

    const [bonusContent, setBonusContent] = useState(getBonusContent());

    useEffect(() => {
        dispatch(clearErrors());
    }, [location.pathname]);

    return (
        <div class="content-wrapper">
            <div class="flex-grow-1 container-p-y container-fluid">
                <h4 class="py-3 mb-4"><span class="text-muted fw-light">Bonuses /</span> Create bonus</h4>

                {(errors && errors.bonuses_create) && (
                    <div className={`alert alert-${errors.bonuses_create.type}`} role="alert">{errors.bonuses_create.message}</div>
                )}

                <div class="card mb-4" style={{marginTop: '20px'}}>
                    <div class="card-body">
                            <form onSubmit={e => { 
                                e.preventDefault();

                                if(buttons.bonuses_create)
                                    return;

                                const data = new FormData(e.target);
                                dispatch(createBonus(data))
                            }}>
                                <input name="content" value={JSON.stringify(bonusContent)} hidden />

                                <h6>1. Bonus information</h6>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Bonus alias</label>
                                    <input type="text" class="form-control" name="alias" placeholder="Enter bonus alias..." required />
                                    <div id="floatingInputHelp" class="form-text">Give a nickname to your character to make it easier for you to edit it.</div>
                                </div>

                                <div className="row">
                                    <div class="col-xl-12">
                                        <div class="nav-align-top mb-4">
                                            <ul class="nav nav-tabs nav-fill" role="tablist">
                                                {merchant.settings.language_list.map((language, key) => {
                                                    let icon = "error-alt",
                                                        color = "red";

                                                    if(bonusContent[language].name && bonusContent[language].description) {
                                                        icon = "check";
                                                        color = "green";
                                                    }

                                                    return (
                                                        <li class="nav-item" role="presentation">
                                                            <button type="button" class={`nav-link ${tab === language ? 'active': ''}`} role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="false" tabindex="-1" onClick={e => setTab(language)}>
                                                                <i class={`tf-icons bx bx-${icon} me-1`} style={{color}}></i> 
                                                                {language}
                                                            </button>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            <div class="tab-content" style={{borderTop: "1px solid #d9dee3"}}>
                                                <h6>Customize the bonus for each language version</h6>

                                                <div className={`alert alert-primary`} role="alert">If you do not specify the data in the language version, the bonus will not be displayed.</div>

                                                <div class="mb-3">
                                                    <label class="form-label" for="basic-default-fullname">Bonus name</label>
                                                    <input type="text" class="form-control" placeholder={`Enter bonus ${tab} name...`} defaultValue={bonusContent[tab].name} key={tab} onInput={e => {
                                                        setBonusContent({
                                                            ...bonusContent,
                                                            [tab]: {
                                                                ...bonusContent[tab],
                                                                name: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                            }
                                                        })
                                                    }} required />
                                                    <div id="floatingInputHelp" class="form-text">The name of your bonus</div>
                                                </div>

                                                <div class="mb-3">
                                                    <label class="form-label" for="basic-default-fullname">Bonus descriptions</label>
                                                    <textarea id="basic-default-message" class="form-control" placeholder={`Enter bonus ${tab} descriptions...`} defaultValue={bonusContent[tab].description} key={tab} onInput={e => {
                                                        setBonusContent({
                                                            ...bonusContent,
                                                            [tab]: {
                                                                ...bonusContent[tab],
                                                                description: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                            }
                                                        })
                                                    }} required></textarea>
                                                    <div id="floatingInputHelp" class="form-text">You can add bonus data to the description by using keywords <code>{`{bonus_freespins} / {bonus_freespins_game} / {bonus_amount} / {bonus_minimal_deposit} / {bonus_maximal_deposit} / {bonus_multiplier} / {bonus_freespins_devider}`}</code></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Bonus Image</label>
                                    <div class="input-group">
                                        <input class="form-control" name="bonus_image" type="file" id="formFile" required />
                                    </div>
                                    <div id="floatingInputHelp" class="form-text">If there are free spins in your bonus, then the bonus will use a picture of the game in which you give free spins.</div>
                                </div>


                                <hr class="my-4 mx-n4"></hr>
                                <h6>2. Bonus details</h6>
                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Bonus type</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="bonus_type" tabindex="-1" aria-hidden="true" onChange={e => setBonusType(e.target.value)}>
                                        <option value="welcome_free" data-select2-id="9">Welcome Bonus [No Deposit]</option>
                                        <option value="welcome_deposit" data-select2-id="9">Welcome Bonus [Deposit]</option>
                                        <option value="deposit_bonus" data-select2-id="9">Deposit Bonus [Deposit]</option>
                                        <option value="multiplier" data-select2-id="9">Mutliplier Bonus [Deposit]</option>
                                        <option value="freespins" data-select2-id="23">Freespins [Deposit]</option>
                                        <option value="phone_bonus" data-select2-id="23">Phone Validation</option>
                                        <option value="whatsapp_bonus" data-select2-id="23">WhatsApp Validation</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">The type of your bonus</div>
                                </div>

                                {/* {bonusType} */}

                                {bonusType === "welcome_free" && <WelcomeFree games={games.filter(game => game.bm)} />}
                                {bonusType === "welcome_deposit" && <WelcomeDeposit games={games.filter(game => game.bm)} />}
                                {bonusType === "deposit_bonus" && <DepositBonus games={games.filter(game => game.bm)} />}
                                {bonusType === "multiplier" && <MultiplierBonus games={games.filter(game => game.bm)} />}
                                {bonusType === "freespins" && <FreespinsBonus games={games.filter(game => game.bm)} />}
                                {bonusType === "whatsapp_bonus" && <WhatsAppBonus games={games.filter(game => game.bm)} />}
                                {bonusType === "phone_bonus" && <PhoneBonus games={games.filter(game => game.bm)} />}

                                <hr class="my-4 mx-n4"></hr>
                                <h6>3. Wager condition</h6>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Bonus mode</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="mode" tabindex="-1" aria-hidden="true">
                                        <option value="bonus" data-select2-id="9">Bonus</option>
                                        <option value="fun" data-select2-id="23">Free Play</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">The mode of your bonus</div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                        <label class="form-label" for="basic-default-fullname">Wagering mode</label>
                                        <select id="collapsible-state" name="wager_type" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                                            <option value="wager" data-select2-id="wager">Wager</option>
                                            <option value="limit_usd" data-select2-id="limit_usd">Redeem (Fixed)</option>
                                            <option value="limit" data-select2-id="limit">Redeem (Multiplier)</option>
                                        </select>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                        <label class="form-label" for="basic-default-fullname">The value of wagering</label>
                                        <input type="number" name="wager_value" class="form-control" placeholder="..." required />
                                    </div>
                                </div>

                                <hr class="my-4 mx-n4"></hr>
                                <h6>4. Games restrictions</h6>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Games restrictions</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="games_restrictions" tabindex="-1" aria-hidden="true" onChange={e => setState({...state, games_restrictions: parseInt(e.target.value)})}>
                                        <option value="0" data-select2-id="9">The bonus can be wagered in all games</option>
                                        <option value="1" data-select2-id="9">The bonus can be wagered in certain games</option>
                                    </select>
                                </div>

                                {state.games_restrictions === 1 && (
                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Wagerings games list</label>
                                        <input name="games_restrictions_list" value={JSON.stringify(state.games_restrictions_list)} hidden />
                                        <Tags
                                            dataKey="games_restrictions_list"
                                            whitelist={games.map(game => `${game.name} // ${game.gameId}`)}
                                            enforceWhitelist={true}
                                            autoComplete={true}
                                            onChange={e => {
                                                console.log(e);

                                                e = e.map(name => {
                                                    const game = games.filter(game => game.name === name.split(' // ')[0])[0] || false;
                                                    return game ? game.gameId : null;
                                                }).filter(g => g);

                                                setState({
                                                    ...state,
                                                    games_restrictions_list: e
                                                })
                                            }}
                                            value={state.games_restrictions_list.map(gameId => {
                                                const game = games.filter(game => game.gameId === gameId)[0] || false;
                                                return game ? `${game.name} // ${game.gameId}` : null;
                                            }).filter(g => g)} />
                                        <div id="floatingInputHelp" class="form-text">Select the list of games where the voucher wagering will be available</div>
                                    </div>
                                )}

                                <hr class="my-4 mx-n4"></hr>
                                <h6>5. Bonus access</h6>

                                {!['whatsapp_bonus', 'phone_bonus'].includes(bonusType) && (
                                    <>
                                        <div class="mb-3">
                                            <label class="form-label" for="basic-default-fullname">Mandatory phone number validation</label>
                                            <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="phone_validation" tabindex="-1" aria-hidden="true">
                                                <option value="0" data-select2-id="23">No</option>
                                                <option value="1" data-select2-id="9">Yes</option>
                                                
                                            </select>
                                            <div id="floatingInputHelp" class="form-text">Does the user have to verify the phone number to activate this bonus</div>
                                        </div>

                                        <div class="mb-3">
                                            <label class="form-label" for="basic-default-fullname">Mandatory WhatsApp validation</label>
                                            <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="whatsapp_validation" tabindex="-1" aria-hidden="true">
                                                <option value="0" data-select2-id="23">No</option>
                                                <option value="1" data-select2-id="9">Yes</option>
                                            </select>
                                            <div id="floatingInputHelp" class="form-text">Does the user have to connect WhatsApp to activate this bonus</div>
                                        </div>
                                    </>
                                )}

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Currency</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="currency" tabindex="-1" aria-hidden="true">
                                        {merchant.settings.currencys_list.map((cur, key) => {
                                            return (
                                                <option value={cur} key={key}>{cur}</option>
                                            )
                                        })}
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">Select the currency in which the created bonus will be available</div>
                                </div>

                                <button type="submit" class="btn btn-primary" disabled={buttons.bonuses_create}>Create bonus</button>
                            </form>
                        </div>
                    </div>
            </div>
        </div>
    )
}