import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/settings/merchant';
import {saveReferralSettings} from '../../../../reducers/agent/actions';
import FormUtil from '../../../../utils/form';

export default ({currency}) => {
    const {merchant, errors, buttons} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <>
            {!merchant.settings.currencys_list.includes(currency) && <div class="alert alert-info" role="alert">Currency <b>{currency}</b> is not enabled in <b>General settings</b></div>}

            {(errors && errors.settings_referrals) && (
                <div className={`alert alert-${errors.settings_referrals.type}`} role="alert">{errors.settings_referrals.message}</div>
            )}

            <div class="card-body">
                <form onSubmit={e => {
                    e.preventDefault();

                    if(buttons.settings_referrals)
                        return;

                    const data = FormUtil(e);
                    dispatch(saveReferralSettings(data));
                }}>
                    <input type="hidden" hidden readOnly name="preference" value={currency} />

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">First deposit bonus (%)</label>
                            <input type="number" name="referral_first_deposit_percent" defaultValue={merchant.settings.currencys[currency].referral_first_deposit_percent} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">Referral's First Deposit Bonus</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Each deposit bonus (%)</label>
                            <input type="number" name="referral_each_deposit_percent" defaultValue={merchant.settings.currencys[currency].referral_each_deposit_percent} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">Bonus for each referral deposit</div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Referral link</label>
                        <input type="text" name="referral_link" value={merchant.settings.links.referral} class="form-control" placeholder="" readOnly disabled required key={currency}/>
                        <div id="floatingInputHelp" class="form-text">Referral Link Template (set by the moderator)</div>
                    </div>

                    <button type="submit" class="btn btn-primary" disabled={buttons.settings_referrals}>Save</button>
                </form>
            </div>
        </>
    )
}