export default {
    loaded: 'vouchers.loaded',
    add: 'vouchers.add',
    remove: 'vouchers.remove',
    update: 'vouchers.update',
    reindex: 'vouchers.reindex',
    error: "vouchers.error",
    error_remove: "vouchers.error.remove",
    toggleButton: "vouchers.toggle.button",
    toggleVoucher: "vouchers.toggle.voucher"
}