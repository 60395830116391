import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/loaders/bonuses';
import {loadGames} from '../../reducers/games/actions';
import {loadBonuses} from '../../reducers/bonuses/actions';
import LoaderPage from '../pages/loader';

export default ({children}) => {
    const {loaded, games, bonuses} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        if(!loaded) {
            if(!bonuses)
                dispatch(loadBonuses());

            if(!games)
                dispatch(loadGames());
        }
    }, [loaded]);

    if(!loaded)
        return <LoaderPage />

    return children;
}