import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.bonuses.loaded,
    props.games.loaded,
    (bonuses, games) => {
        return {
            loaded: bonuses && games,
            bonuses,
            games
        }
    }
)