import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.bonuses.buttons.all,
    props.bonuses.errors.all,
    props.bonuses.bonuses,
    props.agent.merchant,
    (buttons, errors, bonuses, merchant) => {
        return {
            buttons,
            errors,
            bonuses,
            merchant
        }
    }
)