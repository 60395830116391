import Create from './create';
import List from './list';
import Edit from './edit';
import SlidersLoader from '../../loaders/sliders';
import {Routes, Route} from 'react-router-dom';
import NotFound from '../notfound';

export default () => (
    <SlidersLoader>
        <Routes>
            <Route exact path='/create' element={<Create />} />
            <Route exact path='/:slider_id' element={<Edit />} />
            <Route exact path='/' element={<List />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    </SlidersLoader>
)