import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/loaders/games-patterns';
import {loadPatterns, loadGames} from '../../reducers/games/actions';
import LoaderPage from '../pages/loader';

export default ({children}) => {
    const {patterns, games} = useSelector(Selector),
          dispatch = useDispatch(),
          loaded = patterns !== null && games;

    useEffect(() => {
        if(!loaded) {
            if(!patterns)
                dispatch(loadPatterns());

            if(!games)
                dispatch(loadGames());
        }
    }, [loaded]);

    if(!loaded)
        return <LoaderPage />

    return children;
}