import types from './types';

const InitialState = {
    loaded: false,
    vouchers: [],
    errors: {
        vouchers_create: null,
        vouchers_list: null,
        vouchers_edit: null
    },
    buttons: {
        vouchers_create: false,
        vouchers_list: false,
        vouchers_edit: false,
        vouchers_generate: false,
        vouchers_edit_image: false
    }
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.toggleVoucher:
            return {
                ...state,
                vouchers: state.vouchers.map(b => {
                    return b.id === payload ? {
                        ...b,
                        disabled: !b.disabled
                    } : b;
                })
            }
        case types.loaded:
            return {
                ...state,
                loaded: true,
                vouchers: payload.vouchers.map(b => {
                    return {
                        ...b,
                        disabled: false,
                        timestamp: b.timestamp + Date.now(),
                    }
                })
            }
        case types.remove:
            return {
                ...state,
                vouchers: state.vouchers.filter(voucher => voucher.id !== payload)
            }
        case types.add:
            return {
                ...state,
                vouchers: [
                    ...state.vouchers,
                    {
                        ...payload,
                        disabled: false,
                        timestamp: payload.timestamp + Date.now()
                    }
                ]
            }
        case types.update:
            return {
                ...state,
                vouchers: state.vouchers.map(voucher => {
                    return voucher.id === payload.id ? {
                        ...voucher,
                        ...payload
                    } : voucher;
                })
            }
        case types.error_remove:
            return {
                ...state,
                errors: InitialState.errors
            }
        case types.error:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [payload.key]: {
                        type: payload.type,
                        message: payload.message
                    }
                }
            }
        case types.toggleButton:
            return {
                ...state,
                buttons: {
                    ...state.buttons,
                    [payload]: !state.buttons[payload]
                }
            }
        default:
            return state;
    }
}