import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.games.buttons.all,
    props.games.errors.all,
    props.games.patterns,
    props.agent.merchant,
    (buttons, errors, patterns, merchant) => {
        return {
            buttons,
            errors,
            patterns,
            merchant
        }
    }
)