import {combineReducers} from 'redux';
import agent from './agent/reducer';
import games from './games/reducer';
import bonuses from './bonuses/reducer';
import vouchers from './vouchers/reducer';
import sliders from './sliders/reducer';

export default combineReducers({
    agent,
    games,
    bonuses,
    vouchers,
    sliders
});