import types from './types';
import API from '../../lib/api';

export const loadSliders = () => async dispatch => {
    const response = await API({
        path: '/sliders/get'
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: response
        });
}

export const toggleSlider = id => async dispatch => {
    dispatch({
        type: types.toggleSlider,
        payload: id
    });

    const response = await API({
        path: '/sliders/toggle',
        body: {
            id
        }
    });

    dispatch({
        type: types.toggleSlider,
        payload: id
    });

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "sliders_list",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.update,
            payload: {
                id,
                status: response.nextStatus
            }
        });

        dispatch({
            type: types.error,
            payload: {
                key: "sliders_list",
                ...response.message
            }
        });
    }
}

export const createSlider = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "sliders_create"
    });

    dispatch({
        type: types.toggleButton,
        payload: "sliders_create"
    });

    const response = await API({
        path: '/sliders/create',
        body: data,
        file: true
    });

    dispatch({
        type: types.toggleButton,
        payload: "sliders_create"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "sliders_create",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.add,
            payload: response.slider
        });

        dispatch({
            type: types.error,
            payload: {
                key: "sliders_create",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const changeIndex = (sliderId, indexType) => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: 'sliders_list'
    });

    dispatch({
        type: types.toggleSlider,
        payload: sliderId
    });

    const response = await API({
        path: '/sliders/changeIndex',
        body: {
            id: sliderId,
            type: indexType
        }
    });

    dispatch({
        type: types.toggleSlider,
        payload: sliderId
    });
    
    if(!response.success) {
        window.scrollTo(0, 0);

        dispatch({
            type: types.error,
            payload: {
                key: "sliders_list",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.reindex,
            payload: response.indexes
        });
    }
}

export const uploadSliderImage = (data) => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "sliders_edit"
    });

    dispatch({
        type: types.toggleButton,
        payload: 'sliders_edit'
    });

    const response = await API({
        path: '/sliders/image',
        body: data,
        file: true
    });


    dispatch({
        type: types.toggleButton,
        payload: 'sliders_edit'
    });  

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "sliders_edit",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.update,
            payload: response.slider
        });

        dispatch({
            type: types.error,
            payload: {
                key: "sliders_edit",
                type: response.message.type,
                message: response.message.message
            }
        });
    }  
}

export const saveSlider = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "sliders_edit"
    });

    dispatch({
        type: types.toggleButton,
        payload: "sliders_edit"
    });

    const response = await API({
        path: '/sliders/save',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: "sliders_edit"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "sliders_edit",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.update,
            payload: response.slider
        });

        dispatch({
            type: types.error,
            payload: {
                key: "sliders_edit",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}


export const removeSlider = sliderId => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: 'sliders_list'
    });

    dispatch({
        type: types.toggleSlider,
        payload: sliderId
    });

    const response = await API({
        path: '/sliders/remove',
        body: {
            id: sliderId
        }
    });

    dispatch({
        type: types.toggleSlider,
        payload: sliderId
    });

    window.scrollTo(0, 0);
    
    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "sliders_list",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.remove,
            payload: sliderId
        });

        dispatch({
            type: types.reindex,
            payload: response.indexes
        });

        dispatch({
            type: types.error,
            payload: {
                key: "sliders_list",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const publishMessage = ({
    key,
    type,
    message
}) => {
    return {
        type: types.error,
        payload: {
            key,
            type,
            message
        }
    }
}

export const clearErrors = () => {
    return {
        type: types.error_remove
    }
}