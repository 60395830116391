import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/loaders/vouchers';
import {loadGames} from '../../reducers/games/actions';
import {loadVouchers} from '../../reducers/vouchers/actions';
import LoaderPage from '../pages/loader';

export default ({children}) => {
    const {loaded, games, vouchers} = useSelector(Selector),
          dispatch = useDispatch()

    useEffect(() => {
        if(!games)
            dispatch(loadGames());

        if(!vouchers)
            dispatch(loadVouchers());
    }, [loaded]);

    if(!loaded)
        return <LoaderPage />

    return children;
}