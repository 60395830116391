import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.agent.buttons.login,
    props.agent.errors.auth,
    (button, error) => {
        return {
            button,
            error
        }
    }
)