import {useState, useEffect} from 'react';
import Currency from './currency';
import {useSelector, useDispatch} from 'react-redux';
import MerchantSelector from '../../../../selectors/merchant';
import {clearErrors} from '../../../../reducers/agent/actions';
import {useLocation} from 'react-router-dom';


export default () => {
    const {merchant} =  useSelector(MerchantSelector),
          [tab, setTab] = useState(merchant.settings.currencys_list[0]),
          dispatch = useDispatch(),
          location = useLocation();

    useEffect(() => {
        dispatch(clearErrors());
    }, [tab, location.pathname]);

    return (
           <div class="content-wrapper">
                <div class="flex-grow-1 container-p-y container-fluid">
                    <h4 class="py-3 mb-4"><span class="text-muted fw-light">Settings /</span> Cashback</h4>
                    <div className="row">
                        <div class="col-xl-12">
                            <div class="nav-align-top mb-4">
                                <ul class="nav nav-tabs nav-fill" role="tablist">
                                    {merchant.settings.currencys_list.map((cur, key) => {
                                        return (
                                            <li class="nav-item" role="presentation">
                                                <button type="button" class={`nav-link ${tab === cur ? 'active': ''}`} role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="false" tabindex="-1" onClick={e => setTab(cur)}>
                                                    <i class="tf-icons bx bx-wallet me-1"></i> 
                                                    {cur}
                                                </button>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <div class="tab-content">
                                    <Currency currency={tab} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}