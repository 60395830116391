import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/loaders/sliders';
import {loadBonuses} from '../../reducers/bonuses/actions';
import {loadSliders} from '../../reducers/sliders/actions';
import LoaderPage from '../pages/loader';

export default ({children}) => {
    const {loaded, bonuses, sliders} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        if(!loaded) {
            if(!bonuses)
                dispatch(loadBonuses());

            if(!sliders)
                dispatch(loadSliders());
        }
    }, [loaded]);

    if(!loaded)
        return <LoaderPage />

    return children;
}