import {Link} from 'react-router-dom';

export default () => (
    <div class="container-xxl container-p-y">
        <div class="misc-wrapper" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <h2 class="mb-2 mx-2">Page Not Found :(</h2>
            <p class="mb-4 mx-2" style={{textAlign: 'center'}}>Oops! 😖 The requested URL was not found on this server.</p>
            <Link to="/" class="btn btn-primary">Back to home</Link>
            <div class="mt-3">
                <img src={`${window.location.origin}/assets/img/illustrations/page-misc-error-light.png`} alt="page-misc-error-light" width="500" class="img-fluid"/>
            </div>
        </div>
    </div>
)