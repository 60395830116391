import {useState} from 'react';

export default ({games}) => {
    const [preset, setPreset] = useState({
        freespins_enabled: false,
        freespins_game_type: "random",
        freespins_type: "fixed"
    });

    return (
        <>
            <div class="mb-3">
                <label class="form-label" for="basic-default-fullname">Deposit Include</label>
                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" disabled data-select2-id="collapsible-state" name="deposit_include" tabindex="-1" aria-hidden="true">
                    <option value="0" data-select2-id="9">No</option>
                </select>
                <input name="deposit_include" value={0} readOnly hidden />
            </div>

            <div class="mb-3">
                <label class="form-label" for="basic-default-fullname">Bonus amount type</label>
                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" value={"amount"} name="bonus_amount_type" tabindex="-1" aria-hidden="true" onChange={e => setPreset({...preset, bonus_type: e.target.value})}>
                    <option value="amount" data-select2-id="9">Amount</option>
                </select>
            </div>   

            <div class="mb-3">
                <label class="form-label" for="basic-default-fullname">Bonus amount</label>
                <input type="number" class="form-control" name="bonus_amount" placeholder="Enter bonus amount..." required />
                <div id="floatingInputHelp" class="form-text">The amount that will be given to the player (<code>{`{bonus_amount}`}</code>)</div>
            </div>

            <div class="mb-3">
                <label class="form-label" for="basic-default-fullname">Bonus freespins</label>
                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="freespins_enabled" tabindex="-1" aria-hidden="true" onChange={e => setPreset({...preset, freespins_enabled: parseInt(e.target.value)})}>
                    <option value="0" data-select2-id="9">Disabled</option>
                    <option value="1" data-select2-id="9">Enabled</option>
                </select>
            </div>

            {preset.freespins_enabled && (
                <>
                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Bonus freespins count</label>
                        <input type="number" class="form-control" name="freespins_count" placeholder="Enter freespins count..." required />
                        <div id="floatingInputHelp" class="form-text">The number of free spins that the player will receive (<code>{`{bonus_freespins}`}</code>)</div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Bonus freespins game</label>
                        <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="freespins_game_type" tabindex="-1" aria-hidden="true" onChange={e => setPreset({...preset, freespins_game_type: e.target.value})}>
                            <option value="random" data-select2-id="9">Random</option>
                            <option value="choose" data-select2-id="9">Choose games</option>
                        </select>
                    </div>

                    {preset.freespins_game_type !== "random" && (
                        <div class="mb-3">
                            <label class="form-label" for="basic-default-fullname">Select freespins game</label>
                            <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="freespins_game" tabindex="-1" aria-hidden="true">
                                {games.map(game => {
                                    return (
                                        <option value={game.gameId} data-select2-id="9">{game.name}</option>
                                    )
                                })}
                            </select>
                            <div id="floatingInputHelp" class="form-text">Select a specific game in which the free spins will be played (<code>{`{bonus_freespins_game}`}</code>)</div>
                        </div>
                    )}
                </>
            )}

        </>
    )
}