export default ({
    path,
    method="POST",
    headers={},
    body,
    file=false
}) => new Promise(async(resolve) => {
    fetch(`${window.location.origin}/api${path}`, {
        method,
        headers: file ? {...headers} : {
            "Content-Type": "application/json",
            ...headers
        },
        body: file ? body : JSON.stringify(body),
    }).then(res => res.json()).then(res => resolve(res)).catch(e => {
        return resolve({
            success: false,
            error: "Failed connect to server"
        });
    })
});