import Create from './create';
import List from './list';
import Edit from './edit';
import BonusesLoader from '../../loaders/bonuses';
import {Routes, Route} from 'react-router-dom';
import NotFound from '../notfound';

export default () => (
    <BonusesLoader>
        <Routes>
            <Route exact path='/create' element={<Create />} />
            <Route exact path='/:bonus_id' element={<Edit />} />
            <Route exact path='/' element={<List />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    </BonusesLoader>
)