import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.agent.methods,
    props.agent.merchant,
    props.agent.buttons.all,
    props.agent.errors.all,
    (methods, merchant, buttons, errors) => {
        return {
            methods,
            merchant,
            buttons,
            errors
        }
    }
)