import {useState} from 'react';

export default ({
    title,
    show,
    defaultSort={column: '', type: ''},
    searchFilter,
    columns,
    entities
}) => {
    const [preset, setPreset] = useState({
        show: show[0],
        search: '',
        sort: defaultSort,
        page: 1
    });

    const getSortedEntities = () => {
        let _entities = entities.slice(),
            column = columns.filter(c => c.column_key === preset.sort.column)[0] || false;

        if(column) {
            _entities = _entities.sort((a, b) => {
                const a_value = column.sortable_value(a),
                      b_value = column.sortable_value(b);
                      
                if(typeof a_value === 'string' && typeof b_value === 'string') {
                    const localeCompare = a_value.localeCompare(b_value);

                    if(preset.sort.type === 'desc') {
                        if(localeCompare === -1) return 1;
                        if(localeCompare === 1) return -1;
                        return 0;
                    }

                    return localeCompare;
                }

                if(typeof a_value === 'number' && typeof b_value === 'number') {
                    if(a_value < b_value) return preset.sort.type === 'asc' ? -1 : 1;
                    if(a_value > b_value) return preset.sort.type === 'asc' ? 1 : -1;
                    return 0;
                }
            });
        }

        if(preset.search.length > 0)
            _entities = _entities.filter(entity => {
                const search_filter_value = searchFilter(entity),
                      search_filter = Object.values(search_filter_value).join(',');

                return search_filter.toLowerCase().indexOf(preset.search.toLowerCase()) >= 0;
            });

        return _entities; 
    }

    const sorted_entities = getSortedEntities();

    const Pagination = () => {
        const max_pages = preset.show === 'all' ? 1 : Math.ceil(sorted_entities.length / preset.show),
              currentPage = preset.page,
              pages = [];

        if(max_pages <= 7) {
            for(let i = 0; i < max_pages; i++)
                pages.push({page: i + 1, type: 'page'});
        } else {
            if(currentPage < 5) {
                for(let i = 0; i < 5; i++) pages.push({page: i+1, type: 'page'})
                pages.push({page: '...', type: 'nonpage'});
                pages.push({page: max_pages, type: 'page'});
            } else if(currentPage > (max_pages - 4)) {
                pages.push({page: 1, type: 'page'});
                pages.push({page: '...', type: 'nonpage'});
                pages.push({page: max_pages-4, type: 'page'});
                pages.push({page: max_pages-3, type: 'page'});
                pages.push({page: max_pages-2, type: 'page'});
                pages.push({page: max_pages-1, type: 'page'});
                pages.push({page: max_pages, type: 'page'});
            } else if(currentPage >= 5) {
                pages.push({page: 1, type: 'page'});
                pages.push({page: '...', type: 'nonpage'});
                pages.push({page: currentPage-1, type: 'page'});
                pages.push({page: currentPage, type: 'page'});
                pages.push({page: currentPage+1, type: 'page'});
                pages.push({page: '...', type: 'nonpage'});
                pages.push({page: max_pages, type: 'page'});
            }
        }

        return (
            <ul class="pagination">
                <li class={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''}`} id="DataTables_Table_0_previous">
                    <a aria-controls="DataTables_Table_0" aria-disabled="true" role="link" data-dt-idx="previous" tabindex="0" class="page-link" onClick={e => {
                        e.preventDefault();

                        if((currentPage - 1) < 1)
                            return;

                        setPreset({
                            ...preset,
                            page: currentPage - 1
                        });
                    }}>Previous</a>
                </li>
                {pages.map((page, key) => {
                    return (
                        <li className={`paginate_button page-item ${page.page === currentPage ? 'active' : ''} ${page.type !== 'page' ? 'disabled' : ''}`} key={key}>
                            <a href="" aria-controls="DataTables_Table_0" role="link" aria-current="page" data-dt-idx="0" tabindex="0" class="page-link" onClick={e => {
                                e.preventDefault();

                                if(page.type === 'page')
                                    setPreset({
                                        ...preset,
                                        page: page.page
                                    });
                            }}>{page.page}</a>
                        </li>
                    )
                })}
                <li className={`paginate_button page-item next ${(currentPage + 1) > max_pages ? 'disabled' : ''}`} id="DataTables_Table_0_next">
                    <a href="" aria-controls="DataTables_Table_0" role="link" data-dt-idx="next" tabindex="0" class="page-link" onClick={e => {
                        e.preventDefault();

                        if((currentPage + 1) > max_pages)
                            return;

                        setPreset({
                            ...preset,
                            page: currentPage + 1
                        });                        
                    }}>Next</a>
                </li>
            </ul>
        )
    }

    const getEntitiesToShow = () => {
        if(preset.show === 'all')
            return sorted_entities;

        return sorted_entities.filter((entity, key) => (key >= ((preset.page -1) * preset.show)) && (key < (preset.page * preset.show)));;
    }

    const sortableColumnClickHandler = column => {
        if(preset.sort.column === column)
            setPreset({
                ...preset,
                sort: {
                    ...preset.sort,
                    type: preset.sort.type === 'asc' ? 'desc' : 'asc'
                }
            });
        else
            setPreset({...preset, sort: {
                column,
                type: 'asc'
            }})
    }

    const entities_to_show = getEntitiesToShow();

    const EntitiesText = () => {
        const from = preset.show === 'all' ? 0 : (preset.page - 1) * preset.show,
              to = preset.show === 'all' ? sorted_entities.length : preset.page * preset.show;

        return (
            <>
                Showing {from + 1} to {to > sorted_entities.length ? sorted_entities.length : to} of {sorted_entities.length} entries
            </>
        );
    }

    return (
        <div class="card">
            <div class="card-datatable table-responsive">
                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                    <div class="card-header flex-column flex-md-row">
                        <div class="head-label text-center">
                            <h5 class="card-title mb-0">{title}</h5>
                            {/* <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="DataTables_Table_0_length">

                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-between" style={{alignItems: 'center'}}>    
                                <label style={{width: '100%'}}>
                                    Show 
                                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="form-select" style={{margin: '0px 10px', width: '100px', display: 'inline'}} defaultValue={preset.show} onChange={e => setPreset({...preset, page: 1, show: e.target.value === 'all' ? e.target.value : parseInt(e.target.value)})}>
                                        {show.map((value, key) => {
                                            return (
                                                <option key={key} value={value === 'All' ? 'all' : value}>{value}</option>
                                            )
                                        })}
                                    </select>
                                    entries
                                </label>
                            </div>
                        <div class="col-sm-12 col-md-6 d-flex justify-content-center justify-content-md-end">
                            <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                <label>
                                    Search:
                                    <input 
                                        type="search" 
                                        class="form-control" 
                                        placeholder="" 
                                        aria-controls="DataTables_Table_0"
                                        onInput={e => setPreset({...preset, search: e.target.value})} />
                                </label></div>
                        </div>
                    </div>
                    <table class="datatables-basic table border-top dataTable no-footer dtr-column">
                        <thead>
                            <tr>
                                {columns.map((column, key) => {
                                    return (
                                        <th key={key} className={`${column.sortable ? 'sorting' : ''} ${(preset.sort.column === column.column_key) ? `sorting_${preset.sort.type}` : ''}`} onClick={e => {
                                            if(!column.sortable)
                                                return;

                                            sortableColumnClickHandler(column.column_key);
                                        }}>{column.column}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                            {entities_to_show.length === 0 && (
                                <tr class="odd">
                                    <td valign="top" colspan="7" class="dataTables_empty">No matching records found</td>
                                </tr>
                            )}
                            {entities_to_show.map((entity, key) => {
                                return (
                                    <tr key={key} style={{
                                        opacity: entity.disabled ? 0.5 : 1,
                                        cursor: entity.disabled ? 'no-drop' : 'unset'
                                    }}>
                                        {columns.map((column, key) => {
                                            return <td style={column.column_style ? column.column_style : {}} key={key} className={typeof column.entity_className == 'function' ? column.entity_className(entity) : ''}>{column.value(entity)}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite"><EntitiesText /></div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                <Pagination />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}