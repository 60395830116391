import {useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/settings/merchant';
import Tagify from '@yaireo/tagify';
import FormUtil from '../../../../utils/form';
import {saveJackpotsSettings} from '../../../../reducers/agent/actions';

export default ({currency}) => {
    const hoursInput = createRef();

    useEffect(() => {
        if(hoursInput.current)
            new Tagify(hoursInput.current, {
                whitelist: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                userInput: true
            });
    });

    const {merchant, errors, buttons} = useSelector(Selector),
          dispatch = useDispatch();

    return (
        <>
            {!merchant.settings.currencys_list.includes(currency) && <div class="alert alert-info" role="alert">Currency <b>{currency}</b> is not enabled in <b>General settings</b></div>}

            {(errors && errors.settings_jackpots) && (
                <div className={`alert alert-${errors.settings_jackpots.type}`} role="alert">{errors.settings_jackpots.message}</div>
            )}

            <div class="card-body">
                <form onSubmit={e => {
                    e.preventDefault();

                    if(buttons.settings_jackpots)
                        return;

                    const data = FormUtil(e);
                    dispatch(saveJackpotsSettings(data))
                }}>

                    <input type="hidden" hidden readOnly name="preference" value={currency} />

                    <h6>1. Jackpot Integration</h6>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Integration type</label>
                        <select id="collapsible-state" name="jackpot_type" value={merchant.settings.currencys[currency].jackpot_type} readOnly disabled class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                            <option value="fake" data-select2-id="9">Fake jackpots</option>
                            <option value="bank" data-select2-id="9">Bank jackpots</option>
                        </select>
                        <div id="floatingInputHelp" class="form-text">The type of jackpot integration (configured by the moderator)</div>
                    </div>

                    {merchant.settings.currencys[currency].jackpot_type === "bank" && (
                        <div class="mb-3">
                            <label class="form-label" for="basic-default-fullname">Integration bank</label>
                            <input type="text" name="jackpot_bank" value={merchant.settings.currencys[currency].jackpot_bank} readOnly disabled  class="form-control" placeholder="20" required key={currency} />
                            <div id="floatingInputHelp" class="form-text">The bank where the jackpot is being managed</div>
                        </div>
                    )}

                    {/* Global jackpot */}

                    <hr class="my-4 mx-n4"></hr>
                    <h6>2. Global jackpot</h6>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Initial jackpot value</label>
                            <input type="number" name="jackpot_global_init_value" defaultValue={merchant.settings.currencys[currency].jackpot_global_init_value} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">The initial value of the jackpot</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Generating fake winnings</label>
                            <select id="collapsible-state" name="jackpot_global_actions" defaultValue={merchant.settings.currencys[currency].jackpot_global_actions ? 1 : 0} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                                <option value="1" data-select2-id="9">Enabled</option>
                                <option value="0" data-select2-id="9">Disabled</option>
                            </select>
                            <div id="floatingInputHelp" class="form-text">The type of jackpot integration (configured by the moderator)</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Minimal interval (ms)</label>
                            <input type="number" name="jackpot_global_interval_from" defaultValue={merchant.settings.currencys[currency].jackpot_global_interval_from} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">Minimal interval between actions</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Maximal interval (ms)</label>
                            <input type="number" name="jackpot_global_interval_to" defaultValue={merchant.settings.currencys[currency].jackpot_global_interval_to} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">Maximal interval between actions</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Minimum additional amount</label>
                            <input type="number" name="jackpot_global_amount_minimal" defaultValue={merchant.settings.currencys[currency].jackpot_global_amount_minimal} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">The minimum amount that will be added to the jackpot every action</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Maximum additional amount</label>
                            <input type="number" name="jackpot_global_amount_maximal" defaultValue={merchant.settings.currencys[currency].jackpot_global_amount_maximal} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">The maximum amount that will be added to the jackpot every action</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Minimum jackpot win amount</label>
                            <input type="number" name="jackpot_global_amount_minimal" defaultValue={merchant.settings.currencys[currency].jackpot_global_win_minimal} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">The minimum jackpot win amount</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Maximum jackpot win amount</label>
                            <input type="number" name="jackpot_global_amount_maximal" defaultValue={merchant.settings.currencys[currency].jackpot_global_win_maximal} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">The maximum jackpot win amount</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="mb-3">
                            <label class="form-label" for="basic-default-fullname">The amount of determining the winner</label>
                            <input type="number" name="jackpot_global_trigger" defaultValue={merchant.settings.currencys[currency].jackpot_global_trigger} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">At the specified amount, a fake winner will be determined</div>
                        </div>
                    </div>

                    {merchant.settings.currencys[currency].jackpot_type === "fake" && (
                        <>
                            {/* Ultimate */}
                            <hr class="my-4 mx-n4"></hr>
                            <h6>3. Ultimate jackpot</h6>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Initial jackpot value</label>
                                    <input type="number" name="jackpot_ultimate_init_value" defaultValue={merchant.settings.currencys[currency].jackpot_ultimate_init_value} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The initial value of the jackpot</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Generating fake winnings</label>
                                    <select id="collapsible-state" name="jackpot_ultimate_actions" defaultValue={merchant.settings.currencys[currency].jackpot_ultimate_actions ? 1 : 0}  class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                                        <option value="1" data-select2-id="9">Enabled</option>
                                        <option value="0" data-select2-id="9">Disabled</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">The type of jackpot integration (configured by the moderator)</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimal interval (ms)</label>
                                    <input type="number" name="jackpot_ultimate_interval_from" defaultValue={merchant.settings.currencys[currency].jackpot_ultimate_interval_from} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">Minimal interval between actions</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximal interval (ms)</label>
                                    <input type="number" name="jackpot_ultimate_interval_to" defaultValue={merchant.settings.currencys[currency].jackpot_ultimate_interval_to} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">Maximal interval between actions</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimum additional amount</label>
                                    <input type="number" name="jackpot_ultimate_amount_minimal" defaultValue={merchant.settings.currencys[currency].jackpot_ultimate_amount_minimal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The minimum amount that will be added to the jackpot every action</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximum additional amount</label>
                                    <input type="number" name="jackpot_ultimate_amount_maximal" defaultValue={merchant.settings.currencys[currency].jackpot_ultimate_amount_maximal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The maximum amount that will be added to the jackpot every action</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimum jackpot win amount</label>
                                    <input type="number" name="jackpot_ultimate_amount_minimal" defaultValue={merchant.settings.currencys[currency].jackpot_ultimate_win_minimal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The minimum jackpot win amount</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximum jackpot win amount</label>
                                    <input type="number" name="jackpot_ultimate_amount_maximal" defaultValue={merchant.settings.currencys[currency].jackpot_ultimate_win_maximal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The maximum jackpot win amount</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">The amount of determining the winner</label>
                                    <input type="number" name="jackpot_ultimate_trigger" defaultValue={merchant.settings.currencys[currency].jackpot_ultimate_trigger} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">At the specified amount, a fake winner will be determined</div>
                                </div>
                            </div>

                            {/* Grand */}
                            <hr class="my-4 mx-n4"></hr>
                            <h6>4. Grand jackpot</h6>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Initial jackpot value</label>
                                    <input type="number" name="jackpot_grand_init_value" defaultValue={merchant.settings.currencys[currency].jackpot_grand_init_value} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The initial value of the jackpot</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Generating fake winnings</label>
                                    <select id="collapsible-state" name="jackpot_grand_actions" defaultValue={merchant.settings.currencys[currency].jackpot_grand_actions ? 1 : 0} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                                        <option value="1" data-select2-id="9">Enabled</option>
                                        <option value="0" data-select2-id="9">Disabled</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">The type of jackpot integration (configured by the moderator)</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimal interval (ms)</label>
                                    <input type="number" name="jackpot_grand_interval_from" defaultValue={merchant.settings.currencys[currency].jackpot_grand_interval_from} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">Minimal interval between actions</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximal interval (ms)</label>
                                    <input type="number" name="jackpot_grand_interval_to" defaultValue={merchant.settings.currencys[currency].jackpot_grand_interval_to} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">Maximal interval between actions</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimum additional amount</label>
                                    <input type="number" name="jackpot_grand_amount_minimal" defaultValue={merchant.settings.currencys[currency].jackpot_grand_amount_minimal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The minimum amount that will be added to the jackpot every action</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximum additional amount</label>
                                    <input type="number" name="jackpot_grand_amount_maximal" defaultValue={merchant.settings.currencys[currency].jackpot_grand_amount_maximal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The maximum amount that will be added to the jackpot every action</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimum jackpot win amount</label>
                                    <input type="number" name="jackpot_grand_amount_minimal" defaultValue={merchant.settings.currencys[currency].jackpot_grand_win_minimal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The minimum jackpot win amount</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximum jackpot win amount</label>
                                    <input type="number" name="jackpot_grand_amount_maximal" defaultValue={merchant.settings.currencys[currency].jackpot_grand_win_maximal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The maximum jackpot win amount</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">The amount of determining the winner</label>
                                    <input type="number" name="jackpot_grand_trigger" defaultValue={merchant.settings.currencys[currency].jackpot_grand_trigger} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">At the specified amount, a fake winner will be determined</div>
                                </div>
                            </div>

                            {/* Major */}
                            <hr class="my-4 mx-n4"></hr>
                            <h6>5. Major jackpot</h6>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Initial jackpot value</label>
                                    <input type="number" name="jackpot_major_init_value" defaultValue={merchant.settings.currencys[currency].jackpot_major_init_value} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The initial value of the jackpot</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Generating fake winnings</label>
                                    <select id="collapsible-state" name="jackpot_major_actions" defaultValue={merchant.settings.currencys[currency].jackpot_major_actions ? 1 : 0} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                                        <option value="1" data-select2-id="9">Enabled</option>
                                        <option value="0" data-select2-id="9">Disabled</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">The type of jackpot integration (configured by the moderator)</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimal interval (ms)</label>
                                    <input type="number" name="jackpot_major_interval_from" defaultValue={merchant.settings.currencys[currency].jackpot_major_interval_from} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">Minimal interval between actions</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximal interval (ms)</label>
                                    <input type="number" name="jackpot_major_interval_to" defaultValue={merchant.settings.currencys[currency].jackpot_major_interval_to} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">Maximal interval between actions</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimum additional amount</label>
                                    <input type="number" name="jackpot_major_amount_minimal" defaultValue={merchant.settings.currencys[currency].jackpot_major_amount_minimal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The minimum amount that will be added to the jackpot every action</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximum additional amount</label>
                                    <input type="number" name="jackpot_major_amount_maximal" defaultValue={merchant.settings.currencys[currency].jackpot_major_amount_maximal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The maximum amount that will be added to the jackpot every action</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimum jackpot win amount</label>
                                    <input type="number" name="jackpot_major_amount_minimal" defaultValue={merchant.settings.currencys[currency].jackpot_major_win_minimal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The minimum jackpot win amount</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximum jackpot win amount</label>
                                    <input type="number" name="jackpot_major_amount_maximal" defaultValue={merchant.settings.currencys[currency].jackpot_major_win_maximal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The maximum jackpot win amount</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">The amount of determining the winner</label>
                                    <input type="number" name="jackpot_major_trigger" defaultValue={merchant.settings.currencys[currency].jackpot_major_trigger} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">At the specified amount, a fake winner will be determined</div>
                                </div>
                            </div>

                            {/* Mini */}
                            <hr class="my-4 mx-n4"></hr>
                            <h6>6. Mini jackpot</h6>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Initial jackpot value</label>
                                    <input type="number" name="jackpot_mini_init_value" defaultValue={merchant.settings.currencys[currency].jackpot_mini_init_value} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The initial value of the jackpot</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Generating fake winnings</label>
                                    <select id="collapsible-state" name="jackpot_mini_actions" defaultValue={merchant.settings.currencys[currency].jackpot_mini_actions ? 1 : 0} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                                        <option value="1" data-select2-id="9">Enabled</option>
                                        <option value="0" data-select2-id="9">Disabled</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">The type of jackpot integration (configured by the moderator)</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimal interval (ms)</label>
                                    <input type="number" name="jackpot_mini_interval_from" defaultValue={merchant.settings.currencys[currency].jackpot_mini_interval_from} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">Minimal interval between actions</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximal interval (ms)</label>
                                    <input type="number" name="jackpot_mini_interval_to" defaultValue={merchant.settings.currencys[currency].jackpot_mini_interval_to} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">Maximal interval between actions</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimum additional amount</label>
                                    <input type="number" name="jackpot_mini_amount_minimal" defaultValue={merchant.settings.currencys[currency].jackpot_mini_amount_minimal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The minimum amount that will be added to the jackpot every action</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximum additional amount</label>
                                    <input type="number" name="jackpot_mini_amount_maximal" defaultValue={merchant.settings.currencys[currency].jackpot_mini_amount_maximal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The maximum amount that will be added to the jackpot every action</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimum jackpot win amount</label>
                                    <input type="number" name="jackpot_mini_amount_minimal" defaultValue={merchant.settings.currencys[currency].jackpot_mini_win_minimal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The minimum jackpot win amount</div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximum jackpot win amount</label>
                                    <input type="number" name="jackpot_mini_amount_maximal" defaultValue={merchant.settings.currencys[currency].jackpot_mini_win_maximal} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">The maximum jackpot win amount</div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">The amount of determining the winner</label>
                                    <input type="number" name="jackpot_mini_trigger" defaultValue={merchant.settings.currencys[currency].jackpot_mini_trigger} class="form-control" placeholder="50" required key={currency}/>
                                    <div id="floatingInputHelp" class="form-text">At the specified amount, a fake winner will be determined</div>
                                </div>
                            </div>
                        </>
                    )}

                    <button type="submit" class="btn btn-primary" disabled={buttons.settings_jackpots}>Save</button>
                </form>
            </div>
        </>
    )
}