import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.agent.merchant,
    props.agent.errors.all,
    props.agent.buttons.all,
    (merchant, errors, buttons) => {
        return {
            merchant,
            errors,
            buttons
        }
    }
)