import types from './types';
import API from '../../lib/api';

export const loadBonuses = () => async dispatch => {
    const response = await API({
        path: '/bonuses/get'
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: response
        });
}

export const createBonus = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "bonuses_create"
    });

    dispatch({
        type: types.toggleButton,
        payload: "bonuses_create"
    });

    const response = await API({
        path: '/bonuses/create',
        body: data,
        file: true
    });

    dispatch({
        type: types.toggleButton,
        payload: "bonuses_create"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "bonuses_create",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.add,
            payload: response.bonus
        });

        dispatch({
            type: types.error,
            payload: {
                key: "bonuses_create",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const uploadBonusImage = (data) => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "bonuses_edit"
    });

    dispatch({
        type: types.toggleButton,
        payload: 'bonuses_edit'
    });

    const response = await API({
        path: '/bonuses/image',
        body: data,
        file: true
    });


    dispatch({
        type: types.toggleButton,
        payload: 'bonuses_edit'
    });  

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "bonuses_edit",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.update,
            payload: response.bonus
        });

        dispatch({
            type: types.error,
            payload: {
                key: "bonuses_edit",
                type: response.message.type,
                message: response.message.message
            }
        });
    }  
}

export const saveBonus = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "bonuses_edit"
    });

    dispatch({
        type: types.toggleButton,
        payload: "bonuses_edit"
    });

    const response = await API({
        path: '/bonuses/save',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: "bonuses_edit"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "bonuses_edit",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.update,
            payload: response.bonus
        });

        dispatch({
            type: types.error,
            payload: {
                key: "bonuses_edit",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}


export const removeBonus = bonusId => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: 'bonuses_list'
    });

    dispatch({
        type: types.toggleBonus,
        payload: bonusId
    });

    const response = await API({
        path: '/bonuses/remove',
        body: {
            id: bonusId
        }
    });

    dispatch({
        type: types.toggleBonus,
        payload: bonusId
    });

    window.scrollTo(0, 0);
    
    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "bonuses_list",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.remove,
            payload: bonusId
        });

        dispatch({
            type: types.reindex,
            payload: response.indexes
        });

        dispatch({
            type: types.error,
            payload: {
                key: "bonuses_list",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const publishMessage = ({
    key,
    type,
    message
}) => {
    return {
        type: types.error,
        payload: {
            key,
            type,
            message
        }
    }
}

export const changeIndex = (bonusId, indexType) => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: 'bonuses_list'
    });

    dispatch({
        type: types.toggleBonus,
        payload: bonusId
    });

    const response = await API({
        path: '/bonuses/changeIndex',
        body: {
            id: bonusId,
            type: indexType
        }
    });

    dispatch({
        type: types.toggleBonus,
        payload: bonusId
    });
    
    if(!response.success) {
        window.scrollTo(0, 0);

        dispatch({
            type: types.error,
            payload: {
                key: "bonuses_list",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.reindex,
            payload: response.indexes
        });
    }
}

export const clearErrors = () => {
    return {
        type: types.error_remove
    }
}