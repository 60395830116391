import Login from './login';
import Loader from './loader';
import Settings from './settings';
import NotFound from './notfound';
import Bonuses from './bonuses';
import Vouchers from './vouchers';
import Sliders from './sliders';
// import Finance from './finance';
import Games from './games';
// import Users from './users';

export default {
    Login,
    Loader,
    Settings,
    NotFound,
    Bonuses,
    Vouchers,
    Sliders,
    Games
}