import {useEffect, createRef} from 'react';
import Tagify from '@yaireo/tagify';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/settings/merchant';
import {publishMessage, uploadLogotype, clearErrors, saveGeneralSettings} from '../../../../reducers/agent/actions';
import {useLocation} from 'react-router-dom';
import FormUtils from '../../../../utils/form';

export default () => {
    const tagsInput = createRef(),
          languagesInput = createRef(),
          keywordsInput = createRef();

    const fileInputRef = createRef(),
          fileFormRef = createRef();

    useEffect(() => {
        if(tagsInput.current)
            new Tagify(tagsInput.current, {
                whitelist: ["RUB", "USD", "PHP", "EUR"],
                userInput: false
            });

        if(languagesInput.current)
            new Tagify(languagesInput.current, {
                whitelist: ["RU", "US"],
                userInput: false
            });

        if(keywordsInput.current)
            new Tagify(keywordsInput.current);
    });

    const {merchant, errors, buttons} = useSelector(Selector),
          dispatch = useDispatch(),
          location = useLocation();

    useEffect(() => {
        dispatch(clearErrors());
    }, [location]);
    
    if(!merchant)
        return <></>;

    return (
        <div class="content-wrapper">
            <div class="flex-grow-1 container-p-y container-fluid">
                <h4 class="py-3 mb-4"><span class="text-muted fw-light">Settings /</span> General</h4>

                {(errors && errors.settings_general) && (
                    <div className={`alert alert-${errors.settings_general.type}`} role="alert">{errors.settings_general.message}</div>
                )}

                <div class="card mb-4" style={{marginTop: '20px'}}>
                    <div class="card-header d-flex justify-content-between align-items-center">
                        <h5 class="mb-0">General settings</h5>
                    </div>
                    <div class="card-body">
                        <form
                            encType="multipart/form-data"
                            style={{display: 'none'}}
                            onSubmit={e => {
                                e.preventDefault();

                                if(!buttons.logotype)
                                    dispatch(uploadLogotype(new FormData(e.target)));
                            }}>
                                <input
                                    ref={fileInputRef}
                                    style={{display: 'none'}}
                                    type="file"
                                    accept=".png, .svg"
                                    name="logotype"
                                    onChange={e => {
                                        const file = e.target.files[0],
                                              size = file.size / (2 ** 20);

                                        if(!['image/png', 'image/svg+xml'].includes(file.type))
                                            return dispatch(publishMessage({
                                                key: "settings_general",
                                                type: 'warning',
                                                message: 'The logotype must be in .png or .svg format'
                                            }));

                                        if(size > 1)
                                            return dispatch(publishMessage({
                                                key: "settings_general",
                                                type: 'warning',
                                                message: 'The maximum file size is 1 MB'
                                            }));

                                        if(fileFormRef.current)
                                            fileFormRef.current.click();
                                    }}
                                    hidden />

                                <button type="submit" ref={fileFormRef}></button>
                            </form>

                            <form onSubmit={e => {
                                e.preventDefault();

                                if(buttons.settings_general)
                                    return;

                                const data = FormUtils(e);
                                dispatch(saveGeneralSettings(data))
                            }}>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Integration type</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" disabled readOnly>
                                        <option value="full" data-select2-id="9">Full-Integration</option>
                                    </select>
                                </div>
                             
                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Currencies</label>
                                    <input class="form-control" name="currencys_list" value={merchant.settings.currencys_list.join(', ')} ref={tagsInput} key={merchant.settings.currencys_list.join(', ')}></input>
                                    <div id="floatingInputHelp" class="form-text">Select the currencies that will be available to your players</div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Languages</label>
                                    <input class="form-control" name="language_list" value={merchant.settings.language_list.join(', ')} ref={languagesInput} key={merchant.settings.language_list.join(', ')}></input>
                                    <div id="floatingInputHelp" class="form-text">Select the versions of the interface languages that will be available to your players</div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Logotype</label>
                                    <div class="input-group">

                                        <button class="btn btn-outline-success" type="button" id="button-addon1" onClick={e => {
                                            if(buttons.logotype)
                                                return;

                                            if(fileInputRef.current)
                                                fileInputRef.current.click();
                                        }}>{buttons.logotype ? 'Loading...' : 'Upload'}</button>
                                        <button class="btn btn-outline-info" type="button" id="button-addon1" onClick={e => {
                                            if(!merchant.settings.general.logotype_path)
                                                return window.alert('Logotype path not found');

                                            window.open(`${window.location.origin}/api/settings${merchant.settings.general.logotype_path}`);
                                        }}>Check</button>
                                        <input type="text" class="form-control" placeholder="Path to logotype" value={merchant.settings.general.logotype_path} aria-label="Example text with button addon" aria-describedby="button-addon1" readOnly disabled />
                                    </div>
                                    <div id="floatingInputHelp" class="form-text">The path to your logo</div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Sitename</label>
                                    <input type="text" class="form-control" name="sitename" placeholder="Sitename" defaultValue={merchant.settings.general.sitename} required />
                                    <div id="floatingInputHelp" class="form-text">Enter the name of your project, it will be displayed in the title</div>
                                </div>

                                {/* <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Main page title</label>
                                    <input type="text" class="form-control" name="main_title" placeholder="Main page title" defaultValue={merchant.settings.general.main_title} required />
                                    <div id="floatingInputHelp" class="form-text">Enter the title of the main page, it will display as follows: <b>Sitename - Main page title</b></div>
                                </div> */}

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Hostname</label>
                                    <input type="text" class="form-control" placeholder="Hostname" value={merchant.host} disabled readOnly />
                                    <div id="floatingInputHelp" class="form-text">The host of your project (configured by the moderator)</div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Descriptions</label>
                                    <textarea id="basic-default-message" class="form-control" name="descriptions" placeholder="Descriptions" defaultValue={merchant.settings.general.descriptions} required></textarea>
                                    <div id="floatingInputHelp" class="form-text">Enter descriptions for better display in search engines</div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Keywords</label>
                                    <input class="form-control" name="keywords" placeholder="Keywords" defaultValue={merchant.settings.general.keywords} required ref={keywordsInput}></input>
                                    <div id="floatingInputHelp" class="form-text">Enter keywords for better display in search engines (<b>separated by commas</b>)</div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">SSL Enabled</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="ssl_enabled" defaultValue={merchant.settings.general.ssl_enabled ? 1 : 0} tabindex="-1" aria-hidden="true">
                                        <option value="1" data-select2-id="9">Yes</option>
                                        <option value="0" data-select2-id="23">No</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">The value of this attribute affects the generation of links within the project</div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Support URL</label>
                                    <input type="text" class="form-control" name="support_url" placeholder="Sitename" defaultValue={merchant.settings.links.support} required />
                                    <div id="floatingInputHelp" class="form-text">Specify a link that clients can use to contact the technical support of the project</div>
                                </div>

                                <button type="submit" class="btn btn-primary" disabled={buttons.settings_general}>Save</button>
                            </form>
                        </div>
                    </div>
                </div>
        </div>
    )
}