import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.bonuses.loaded,
    props.sliders.loaded,
    (bonuses, sliders) => {
        return {
            loaded: bonuses && sliders,
            bonuses,
            sliders
        }
    }
)