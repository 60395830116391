const toggle = (e) => {
    e.preventDefault();

    const is_active = document.body.classList.contains('layout-menu-expanded');

    if(is_active) {
        document.querySelector('.layout-overlay').classList.remove('animate__fadeIn');
        document.querySelector('#layout-menu').classList.remove('animate__fadeInLeft');
        document.querySelector('.layout-overlay').classList.add('animate__fadeOut');
        document.querySelector('#layout-menu').classList.add('animate__fadeOutLeft');
        setTimeout(() => document.body.classList.remove('layout-menu-expanded'), 500);
    } else {
        document.body.classList.add('layout-menu-expanded');
        document.querySelector('.layout-overlay').classList.remove('animate__fadeOut');
        document.querySelector('#layout-menu').classList.remove('animate__fadeOutLeft');
        document.querySelector('.layout-overlay').classList.add('animate__fadeIn');
        document.querySelector('#layout-menu').classList.add('animate__fadeInLeft');
    }
}

const navigationChanged = function() {
    const is_active = document.body.classList.contains('layout-menu-expanded');

    if(is_active) {
        document.querySelector('.layout-overlay').classList.remove('animate__fadeIn');
        document.querySelector('#layout-menu').classList.remove('animate__fadeInLeft');
        document.querySelector('.layout-overlay').classList.add('animate__fadeOut');
        document.querySelector('#layout-menu').classList.add('animate__fadeOutLeft');
        setTimeout(() => document.body.classList.remove('layout-menu-expanded'), 500);
    }

    setActiveElement();
}

const setActiveElement = () => {
    const pathname = window.location.pathname;

    const links = document.querySelectorAll('.menu-link');
    for(let i = 0; i < links.length; i++) links[i].parentElement.classList.remove('active');

    const subs = document.querySelectorAll('.menu-sub');
    for(let i = 0; i < subs.length; i++) subs[i].parentElement.classList.remove('active');
    
    const link = document.querySelector(`.menu-link[href="${pathname}"]`);
    if(link) link.parentElement.classList.add('active');

    for(let i = 0; i < subs.length; i++)
        if(subs[i].querySelector(`a[href="${pathname}"]`))
            subs[i].parentElement.classList.add('active');
}

export default {
    toggle,
    navigationChanged
}