import {createSelector} from 'reselect';
import props from '../../props';

export default createSelector(
    props.bonuses.buttons.all,
    props.bonuses.errors.all,
    props.games.games,
    props.agent.merchant,
    (buttons, errors, games, merchant) => {
        return {
            buttons,
            errors,
            games,
            merchant
        }
    }
)