import {useState, useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/sliders/edit';
import {clearErrors, saveSlider, uploadSliderImage} from '../../../../reducers/sliders/actions';
import {useLocation, useParams} from 'react-router-dom';
import GamesLoader from '../../../loaders/games';
import FormUtils from '../../../../utils/form';
import Picker from '../../../components/picker';
import bonuses from '../../bonuses';
import Tags from '../../../components/tags';
import API from '../../../../lib/api';
import NotFoundPage from '../../notfound';

export default () => {
    const dispatch = useDispatch(),
          location = useLocation(),
          {buttons, errors, sliders, bonuses, games, merchant} = useSelector(Selector),
          {slider_id} = useParams(),
          slider = sliders.filter(s => s.id == slider_id)[0] || false,
          [state, setState] = useState({button_type: "modal", view_mode: "all"}),
          [tab, setTab] = useState(merchant.settings.language_list[0]);

    const fileInputRef = createRef(),
          fileFormRef = createRef();

    const getSlideContent = () => {
        const content = {};
        for(const language of merchant.settings.language_list)
            content[language] = {
                top: (slider && slider.content[language]) ? slider.content[language].top : null,
                main: (slider && slider.content[language]) ? slider.content[language].main : null,
                button: (slider && slider.content[language]) ? slider.content[language].button : null
            }   

        return content;
    }

    const [slideContent, setSlideContent] = useState(getSlideContent());

    useEffect(() => {
        dispatch(clearErrors());
    }, [location.pathname]);

    if(!slider)
        return <NotFoundPage />

    return (
        <GamesLoader>
           <div class="content-wrapper">
                <div class="flex-grow-1 container-p-y container-fluid">
                    <h4 class="py-3 mb-4"><span class="text-muted fw-light">Slider /</span> Edit slide</h4>

                    {(errors && errors.sliders_edit) && (
                        <div className={`alert alert-${errors.sliders_edit.type}`} role="alert">{errors.sliders_edit.message}</div>
                    )}

                    <div class="card mb-4" style={{marginTop: '20px'}}>
                        <div class="card-body">
                            <form
                                encType="multipart/form-data"
                                style={{display: 'none'}}
                                onSubmit={e => {
                                    e.preventDefault();

                                    if(!buttons.sliders_edit_image)
                                        dispatch(uploadSliderImage(new FormData(e.target), slider.id));
                                }}>
                                    <input
                                        ref={fileInputRef}
                                        style={{display: 'none'}}
                                        type="file"
                                        accept=".png, .svg"
                                        name="slide_image"
                                        onChange={e => {
                                            const file = e.target.files[0],
                                                size = file.size / (2 ** 20);

                                            if(!['image/png', 'image/jpeg'].includes(file.type))
                                                return dispatch(publishMessage({
                                                    key: "sliders_edit",
                                                    type: 'warning',
                                                    message: 'The image must be in .png or .svg format'
                                                }));

                                            if(size > 1)
                                                return dispatch(publishMessage({
                                                    key: "sliders_edit",
                                                    type: 'warning',
                                                    message: 'The maximum file size is 1 MB'
                                                }));

                                            if(fileFormRef.current)
                                                fileFormRef.current.click();
                                        }}
                                        hidden />

                                    <input
                                        type="hidden"
                                        hidden
                                        value={slider.id}
                                        name="sliderId" />

                                    <button type="submit" ref={fileFormRef}></button>
                                </form>
                                <form onSubmit={e => {
                                    e.preventDefault();

                                    if(buttons.sliders_edit)
                                        return;

                                    const data = FormUtils(e);
                                    dispatch(saveSlider(data));
                                }}>
                                    <input name="id" value={slider.id} hidden style={{display: 'none'}} />
                                    <input name="content" value={JSON.stringify(slideContent)} hidden />

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Alias</label>
                                        <input type="text" class="form-control" name="alias" defaultValue={slider.alias} placeholder="Enter alias name..." />
                                        <div id="floatingInputHelp" class="form-text">You can give an alias to your slider for a more convenient setup.</div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Slide orientation</label>
                                        <select id="collapsible-state" name="orientation" defaultValue={slider.orientation} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                                            <option value="left">Left</option>
                                            <option value="right">Right</option>
                                        </select>
                                        <div id="floatingInputHelp" class="form-text">Choose the slide orientation for a better display</div>
                                    </div>
                                    
                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Slide Image</label>
                                        <div class="input-group">

                                            <button class="btn btn-outline-success" type="button" id="button-addon1" onClick={e => {
                                                if(buttons.sliders_edit_image)
                                                    return;

                                                if(fileInputRef.current)
                                                    fileInputRef.current.click();
                                            }}>{buttons.sliders_edit_image ? 'Loading...' : 'Upload'}</button>
                                            <button class="btn btn-outline-info" type="button" id="button-addon1" onClick={e => {
                                                if(!slider.image)
                                                    return window.alert('Slider image path not found');

                                                window.open(`${window.location.origin}${slider.image}`);
                                            }}>Check</button>
                                            <input type="text" class="form-control" placeholder="Path to logotype" value={slider.image} key={slider.image} aria-label="Example text with button addon" aria-describedby="button-addon1" readOnly disabled />
                                        </div>
                                        <div id="floatingInputHelp" class="form-text">The picture of the slider. We recommend using the size <b>867 x 660</b></div>
                                    </div>

                                    <div className="row">
                                        <div class="col-xl-12">
                                            <div class="nav-align-top mb-4">
                                                <ul class="nav nav-tabs nav-fill" role="tablist">
                                                    {merchant.settings.language_list.map((language, key) => {
                                                        let icon = "error-alt",
                                                            color = "red";

                                                        if(slideContent[language].top && slideContent[language].main && slideContent[language].button) {
                                                            icon = "check";
                                                            color = "green";
                                                        } else if(!slideContent[language].top && slideContent[language].main && slideContent[language].button) {
                                                            icon = "check";
                                                            color = "orange";
                                                        }

                                                        return (
                                                            <li class="nav-item" role="presentation" key={key}>
                                                                <button type="button" class={`nav-link ${tab === language ? 'active': ''}`} role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="false" tabindex="-1" onClick={e => setTab(language)}>
                                                                    <i class={`tf-icons bx bx-${icon} me-1`} style={{color}}></i> 
                                                                    {language}
                                                                </button>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                <div class="tab-content" style={{borderTop: "1px solid #d9dee3"}}>
                                                    <h6>Customize the slide text for each language version</h6>

                                                    <div className={`alert alert-primary`} role="alert">If you do not specify the data in the language version, the slide will not be displayed.</div>

                                                    <div class="mb-3">
                                                        <label class="form-label" for="basic-default-fullname">Top text</label>
                                                        <input type="text" class="form-control" placeholder={`Enter ${tab} top text...`} key={tab} defaultValue={slideContent[tab].top} onInput={e => {
                                                            setSlideContent({
                                                                ...slideContent,
                                                                [tab]: {
                                                                    ...slideContent[tab],
                                                                    top: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                                }
                                                            })
                                                        }} />
                                                        <div id="floatingInputHelp" class="form-text">The text is displayed at the very top of the slider and is yellow</div>
                                                    </div>

                                                    <div class="mb-3">
                                                        <label class="form-label" for="basic-default-fullname">Main text*</label>
                                                        <textarea id="basic-default-message" class="form-control" placeholder={`Enter ${tab} main text...`} key={tab} defaultValue={slideContent[tab].main} onInput={e => {
                                                            setSlideContent({
                                                                ...slideContent,
                                                                [tab]: {
                                                                    ...slideContent[tab],
                                                                    main: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                                }
                                                            })
                                                        }}></textarea>
                                                        <div id="floatingInputHelp" class="form-text">The main text of the slider</div>
                                                    </div>

                                                    <div class="mb-3">
                                                        <label class="form-label" for="basic-default-fullname">Button text*</label>
                                                        <input id="basic-default-message" class="form-control"  placeholder={`Enter ${tab} button text...`} key={tab} defaultValue={slideContent[tab].button} onInput={e => {
                                                            setSlideContent({
                                                                ...slideContent,
                                                                [tab]: {
                                                                    ...slideContent[tab],
                                                                    button: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                                }
                                                            })
                                                        }} />
                                                        <div id="floatingInputHelp" class="form-text">Enter the name of the button</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                            <label class="form-label" for="basic-default-fullname">Slider button type</label>
                                            <select id="collapsible-state" name="button_type" defaultValue={slider.button_type} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" onChange={e => {
                                                setState({
                                                    ...state,
                                                    button_type: e.target.value
                                                });
                                            }}>
                                                <option value="modal">Opening a modal window</option>
                                                <option value="external_link_blank">Opening an external link in a new window</option>
                                                <option value="external_link">Opening an external link in current window</option>
                                                <option value="internal_link_blank">Opening an internal link in a new window</option>
                                                <option value="internal_link">Opening an internal link in current window</option>
                                            </select>
                                            <div id="floatingInputHelp" class="form-text">Select the type of action that will occur when the button is clicked</div>
                                        </div>

                                        {state.button_type === "modal" && (
                                            <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                                <label class="form-label" for="basic-default-fullname">Modal window</label>
                                                <select id="collapsible-state" name="button_trigger" defaultValue={slider.button_trigger} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                                                    <option value="deposit_modal">Deposit</option>
                                                    <option value="cashout_modal">Cashout</option>
                                                </select>
                                                <div id="floatingInputHelp" class="form-text">Select the modal window that will open when you click on the button</div>
                                            </div>
                                        )}

                                        {(state.button_type === "external_link" || state.button_type === "external_link_blank") && (
                                            <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                                <label class="form-label" for="basic-default-fullname">External link address</label>
                                                <input type="text" class="form-control" name="button_trigger" defaultValue={slider.button_trigger} placeholder="Enter external link..." required />
                                                <div id="floatingInputHelp" class="form-text">Enter the address of the external link to which you will be redirected when you click on the button</div>
                                            </div>
                                        )}

                                        {(state.button_type === "internal_link" || state.button_type === "internal_link_blank") && (
                                            <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                                <label class="form-label" for="basic-default-fullname">Internal link address</label>
                                                <div class="input-group input-group-merge">
                                                    <span class="input-group-text" id="basic-addon34">{merchant.settings.general.ssl_enabled ? `https://` : 'http://'}{merchant.host}/</span>
                                                    <input type="text" class="form-control" id="basic-url3" name="button_trigger" defaultValue={slider.button_trigger} placeholder="..." aria-describedby="basic-addon34" required />
                                                </div>
                                                <div id="floatingInputHelp" class="form-text">Enter the path of the internal link to which you will be redirected when you click on the button</div>
                                            </div>
                                        )}
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Viewing mode</label>
                                        <select id="collapsible-state" name="view_mode" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" onChange={e => {
                                            setState({
                                                ...state,
                                                view_mode: e.target.value
                                            })
                                        }}>
                                            <option value="all">All visitors will see this slide</option>
                                            <option value="auth">Only authorized users will see this slide</option>
                                            <option value="guests">Only the guests will see this slide</option>
                                        </select>
                                        <div id="floatingInputHelp" class="form-text">Which group of users should see this slide</div>
                                    </div>

                                    {state.view_mode === "auth" && (
                                        <>
                                            <div class="mb-3">
                                                <label class="form-label" for="basic-default-fullname">WhatsApp Validation</label>
                                                <select id="collapsible-state" name="whatsapp" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                                                    <option value="all">All users will see this slide</option>
                                                    <option value="verified">This slide will be seen only by those users who have not verified WhatsApp</option>
                                                    <option value="nonverified">Only those users who verified WhatsApp will see this slide.</option>
                                                </select>
                                                <div id="floatingInputHelp" class="form-text">Which group of users should see this slide</div>
                                            </div>

                                            <div class="mb-3">
                                                <label class="form-label" for="basic-default-fullname">Phone number Validation</label>
                                                <select id="collapsible-state" name="phone" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                                                    <option value="all">All users will see this slide</option>
                                                    <option value="verified">This slide will be seen only by those users who have not verified phone number</option>
                                                    <option value="nonverified">Only those users who verified phone number will see this slide.</option>
                                                </select>
                                                <div id="floatingInputHelp" class="form-text">Which group of users should see this slide</div>
                                            </div>
                                        </>
                                    )}

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Select a currency</label>
                                        <select id="collapsible-state" name="currency" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                                            <option value="RUB">RUB</option>
                                            <option value="USD">USD</option>
                                            <option value="EUR">EUR</option>
                                            <option value="PHP">PHP</option>
                                        </select>
                                        <div id="floatingInputHelp" class="form-text">Select the currency in which the slide will be displayed</div>
                                    </div>

                                    <button type="submit" class="btn btn-primary" disabled={buttons.sliders_edit}>Save slide</button>
                                </form>
                            </div>
                        </div>
                </div>
            </div>
        </GamesLoader>
    )
}