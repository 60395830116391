import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {removeBonus, changeIndex} from '../../../../reducers/bonuses/actions';
import DataTable from '../../../components/datatable';


export default ({merchant, errors, currency, bonuses}) => {
    const dispatch = useDispatch(),
          navigate = useNavigate();

    return (
        <>
            <div className="tab-content" style={{paddingBottom: 0}}>
                {!merchant.settings.currencys_list.includes(currency) && <div class="alert alert-info" role="alert">Currency <b>{currency}</b> is not enabled in <b>General settings</b></div>}

                {(errors && errors.bonuses_list) && (
                    <div className={`alert alert-${errors.bonuses_list.type}`} role="alert">{errors.bonuses_list.message}</div>
                )}
            </div>

            <DataTable
                title={'List of bonuses'}
                show={[10, 25, 50, 100, 'All']}
                defaultSort={{column: 'index', type: 'asc'}}
                searchFilter={entity => {
                    return {
                        id: entity.id,
                    }
                }}
                columns={[
                    {
                        column: 'ID', 
                        column_key: 'id',
                        sortable: false,
                        value: entity => `#${entity.id}`
                    },
                    {
                        column: 'Bonus Info',
                        column_key: 'bonus_info',
                        sortable: false,
                        value: entity => {
                            return (
                                <>
                                    <div class="avatar me-2" style={{float: 'left', height: 'auto'}}>
                                        <img src={entity.image} alt="Bonus Image" style={{
                                            height: "auto"
                                        }} /> 
                                    </div>
                                    <span style={{fontWeight: '600'}}>{entity.alias}</span>
                                </>
                            )
                        }
                    },
                    {
                        column: 'Bonus',
                        column_key: 'bonus',
                        sortable: false,
                        value: entity => {
                            let string = '';
                            if(entity.bonus_amount_type === 'percent') string += `+${entity.bonus_amount}%`;
                            if(entity.bonus_amount_type === 'multiplier') string += `Deposit X${entity.bonus_amount}`;
                            if(entity.bonus_amount_type === 'amount') string += `${entity.bonus_amount} ${currency}`;

                            if(entity.freespins_enabled) {
                                if(entity.freespins_type === 'fixed') string += ` / ${entity.freespins_count} FS`;
                                if(entity.freespins_type === "devider") string += ` / ${entity.freespins_minimal_count} - ${entity.freespins_maximal_count} FS [D: ${entity.freespins_count}]`
                            }

                            return string;
                        }
                    },
                    {
                        column: 'Languages',
                        column_key: 'languages',
                        sortable: false,
                        value: entity => {
                            return (
                                <>
                                    {merchant.settings.language_list.map(lang => {
                                        const content = entity.content[lang];

                                        if(!content)
                                            return <span class="badge bg-label-danger" style={{marginRight: "5px"}}>{lang}</span>;

                                        const name = content.name !== null,
                                              description = content.description !== null;

                                        if(name && description) return <span class="badge bg-label-success" style={{marginRight: "5px"}}>{lang}</span>;
                                        return <span class="badge bg-label-danger" style={{marginRight: "5px"}}>{lang}</span>;
                                    })}
                                </>
                            );
                        }
                    },
                    {
                        column: 'Tags',
                        column_key: 'tags',
                        sortable: false,
                        value: entity => {
                            return (
                                <>
                                    <span class="badge bg-label-primary">{entity.currency}</span>
                                    <span class="badge bg-label-success" style={{marginLeft: '5px'}}>{entity.language}</span>
                                    {!entity.deposit_include && <span class="badge bg-label-danger" style={{marginLeft: '5px'}}>No deposit</span>}
                                    {(entity.wager_value < 5) && <span class="badge bg-label-danger" style={{marginLeft: '5px'}}>Low Wager</span>}
                                    {entity.phone_validation ? <span class="badge bg-label-info" style={{marginLeft: '5px'}}>Phone</span> : ''}
                                    {entity.whatsapp_validation ? <span class="badge bg-label-success" style={{marginLeft: '5px'}}>WhatsApp</span> : ''}
                                </>
                            )
                        }
                    },
                    {
                        column: "Index",
                        column_key: 'index',
                        sortable: true,
                        sortable_value: entity => entity.index,
                        value: entity => entity.index  
                    },
                    {
                        column: 'Actions',
                        column_key: 'actions',
                        value: entity => (
                            <div class="dropdown">
                                <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown"><i class="bx bx-dots-vertical-rounded"></i></button>
                                <div class="dropdown-menu">
                                    {(entity.index > 0) && (
                                        <a class="dropdown-item" href="" onClick={e => {
                                            e.preventDefault();

                                            if(entity.disabled)
                                                return;

                                            dispatch(changeIndex(entity.id, 'up'))
                                        }}>
                                            <i class='bx bx-up-arrow-alt me-1' ></i>
                                            Raise it higher
                                        </a>
                                    )}

                                    {(entity.index < (bonuses.length - 1)) && (
                                        <a class="dropdown-item" href="" onClick={e => {
                                            e.preventDefault();
                                            
                                            if(entity.disabled)
                                                return;

                                            dispatch(changeIndex(entity.id, 'down'))
                                        }}>
                                            <i class='bx bx-down-arrow-alt me-1' ></i>
                                            Lower it down
                                        </a>
                                    )}

                                    <a class="dropdown-item" href="" onClick={e => {
                                        e.preventDefault();
                                        navigate(`/bonuses/${entity.id}`);
                                    }}>
                                        <i class='bx bxs-pencil me-1' ></i>
                                        Edit bonus
                                    </a>
                                    <a class="dropdown-item" href="" onClick={e => {
                                        e.preventDefault();

                                        if(entity.disabled)
                                            return;

                                        const result = window.confirm(`Do you really want to remove the bonus «${entity.name}»?`);

                                        if(result)
                                            dispatch(removeBonus(entity.id));
                                    }}>
                                        <i class="bx bx-trash me-1"></i> 
                                        Delete
                                    </a>
                                </div>
                            </div>
                        )
                    }
                ]}
                entities={bonuses} />
        </>
    )
}