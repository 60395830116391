import {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/vouchers/edit';
import {saveVoucher, uploadVoucherImage, clearErrors} from '../../../../reducers/vouchers/actions';
import {useLocation, useParams} from 'react-router-dom';
import GamesLoader from '../../../loaders/games';
import FormUtils from '../../../../utils/form';
import Picker from '../../../components/picker';
import bonuses from '../../bonuses';
import Tags from '../../../components/tags';
import VouchersTypes from '../../../../reducers/vouchers/types';
import API from '../../../../lib/api';
import NotFoundPage from '../../notfound';

export default () => {
    const fileInputRef = useRef(),
          fileFormRef = useRef();

    const dispatch = useDispatch(),
          location = useLocation(),
          {buttons, errors, games, vouchers, merchant} = useSelector(Selector),
          [tab, setTab] = useState(merchant.settings.language_list[0]),
          {voucher_id} = useParams(),
          voucher = vouchers.filter(voucher => voucher.id == voucher_id)[0] || false;

    useEffect(() => {
        dispatch(clearErrors());
    }, [location.pathname]);

    if(!voucher)
        return <NotFoundPage />;

    const [state, setState] = useState({
        expired: voucher.period + voucher.timestamp,
        active_enabled: voucher.active_enabled ? 1 : 0,
        active_period: voucher.active_period,
        type: voucher.type,
        freespins_enabled: voucher.freespins_enabled ? 1 : 0,
        freespins_game_type: voucher.freespins_game_type,
        bonus_type: voucher.bonus_amount_type,
        freespins_type: voucher.freespins_type,
        games_restrictions: voucher.games_restrictions,
        games_restrictions_list: voucher.games_restrictions_list
    });

    const getBonusContent = () => {
        const content = {};
        for(const language of merchant.settings.language_list)
            content[language] = {
                name: (voucher && voucher.content[language]) ? voucher.content[language].name : null,
                description: (voucher && voucher.content[language]) ? voucher.content[language].description : null
            }   

        return content;
    }

    const [voucherContent, setVoucherContent] = useState(getBonusContent());

    return (
        <div class="content-wrapper">
            <div class="flex-grow-1 container-p-y container-fluid">
                <h4 class="py-3 mb-4"><span class="text-muted fw-light">Vouchers /</span> Edit voucher</h4>

                {(errors && errors.vouchers_edit) && (
                    <div className={`alert alert-${errors.vouchers_edit.type}`} role="alert">{errors.vouchers_edit.message}</div>
                )}

                <div class="card mb-4" style={{marginTop: '20px'}}>
                    <div class="card-body">
                        <form
                            encType="multipart/form-data"
                            style={{display: 'none'}}
                            onSubmit={e => {
                                e.preventDefault();

                                if(!buttons.vouchers_edit_image)
                                    dispatch(uploadVoucherImage(new FormData(e.target), voucher.id));
                            }}>
                                <input
                                    ref={fileInputRef}
                                    style={{display: 'none'}}
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    name="bonus_image"
                                    onChange={e => {
                                        const file = e.target.files[0],
                                            size = file.size / (2 ** 20);

                                        if(!['image/png', 'image/jpeg'].includes(file.type))
                                            return dispatch(publishMessage({
                                                key: "vouchers_edit",
                                                type: 'warning',
                                                message: 'The image must be in .png or .svg format'
                                            }));

                                        if(size > 1)
                                            return dispatch(publishMessage({
                                                key: "vouchers_edit",
                                                type: 'warning',
                                                message: 'The maximum file size is 1 MB'
                                            }));

                                        if(fileFormRef.current)
                                            fileFormRef.current.click();
                                    }}
                                    hidden />

                                <input
                                    type="hidden"
                                    hidden
                                    value={voucher.id}
                                    name="voucherId" />

                                <button type="submit" ref={fileFormRef}></button>
                            </form>
                            <form onSubmit={e => {
                                e.preventDefault();

                                if(buttons.vouchers_edit)
                                    return;

                                const data = FormUtils(e);
                                dispatch(saveVoucher(data))
                            }}>
                                <input type="hidden" name="id" value={voucher.id} hidden />
                                <input name="content" value={JSON.stringify(voucherContent)} hidden />
                                <h6>1. Voucher information</h6>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Voucher alias</label>
                                    <input type="text" class="form-control" name="alias" defaultValue={voucher.alias} placeholder="Enter voucher alias..." required />
                                    <div id="floatingInputHelp" class="form-text">Give alias to your voucher to make it easier for you to edit itr</div>
                                </div>

                                <div className="row">
                                    <div class="col-xl-12">
                                        <div class="nav-align-top mb-4">
                                            <ul class="nav nav-tabs nav-fill" role="tablist">
                                                {merchant.settings.language_list.map((language, key) => {
                                                    let icon = "error-alt",
                                                        color = "red";

                                                    if(voucherContent[language].name && voucherContent[language].description) {
                                                        icon = "check";
                                                        color = "green";
                                                    }

                                                    return (
                                                        <li class="nav-item" role="presentation">
                                                            <button type="button" class={`nav-link ${tab === language ? 'active': ''}`} role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="false" tabindex="-1" onClick={e => setTab(language)}>
                                                                <i class={`tf-icons bx bx-${icon} me-1`} style={{color}}></i> 
                                                                {language}
                                                            </button>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            <div class="tab-content" style={{borderTop: "1px solid #d9dee3"}}>
                                                <h6>Customize the bonus for each language version</h6>

                                                <div className={`alert alert-primary`} role="alert">If you do not specify the data in the language version, the voucher will not be displayed.</div>

                                                <div class="mb-3">
                                                    <label class="form-label" for="basic-default-fullname">Voucher name</label>
                                                    <input type="text" class="form-control" placeholder={`Enter bonus ${tab} name...`} key={tab} defaultValue={voucherContent[tab].name} onInput={e => {
                                                        setVoucherContent({
                                                            ...voucherContent,
                                                            [tab]: {
                                                                ...voucherContent[tab],
                                                                name: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                            }
                                                        })
                                                    }} required />
                                                    <div id="floatingInputHelp" class="form-text">The name of your voucher</div>
                                                </div>

                                                <div class="mb-3">
                                                    <label class="form-label" for="basic-default-fullname">Voucher descriptions</label>
                                                    <textarea id="basic-default-message" class="form-control" placeholder={`Enter bonus ${tab} descriptions...`} key={tab} defaultValue={voucherContent[tab].description} onInput={e => {
                                                        setVoucherContent({
                                                            ...voucherContent,
                                                            [tab]: {
                                                                ...voucherContent[tab],
                                                                description: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                            }
                                                        })
                                                    }} required></textarea>
                                                    <div id="floatingInputHelp" class="form-text">You can add voucher data to the description by using keywords <code>{`{bonus_freespins} / {bonus_freespins_game} / {bonus_amount} / {bonus_minimal_deposit} / {bonus_maximal_deposit} / {bonus_multiplier} / {bonus_freespins_devider}`}</code></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Voucher Image</label>
                                    <div class="input-group">

                                        <button class="btn btn-outline-success" type="button" id="button-addon1" onClick={e => {
                                            if(buttons.vouchers_edit_image)
                                                return;

                                            if(fileInputRef.current)
                                                fileInputRef.current.click();

                                        }}>{buttons.vouchers_edit_image ? 'Loading...' : 'Upload'}</button>
                                        <button class="btn btn-outline-info" type="button" id="button-addon1" onClick={e => {
                                            if(!voucher.image)
                                                return window.alert('Image path not found');

                                            window.open(voucher.image);
                                        }}>Check</button>
                                        <input type="text" class="form-control" placeholder="Path to logotype" value={voucher.image} key={voucher.image} aria-label="Example text with button addon" aria-describedby="button-addon1" readOnly disabled />
                                    </div>
                                    <div id="floatingInputHelp" class="form-text">If there are free spins in your bonus, then the bonus will use a picture of the game in which you give free spins.</div>
                                </div>


                                <hr class="my-4 mx-n4"></hr>
                                <h6>2. Activation rules</h6>

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                        <label class="form-label" for="basic-default-fullname">Activation code</label>
                                        <input type="text" class="form-control" name="code" value={voucher.code} placeholder="Enter activation code..." disabled readOnly required />
                                        <div id="floatingInputHelp" class="form-text">The name of your voucher</div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                        <label class="form-label" for="basic-default-fullname">Activations count</label>
                                        <input type="number" name="count" class="form-control" placeholder="100" defaultValue={voucher.count} required />
                                        <div id="floatingInputHelp" class="form-text">Limit the amount of voucher activation</div>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Activation period</label>
                                    <input type="hidden" name="period" value={state.expired - voucher.timestamp} hidden />
                                    <Picker singleDatePicker={true} startDate={state.expired} dataKey="voucher-create" onChange={e => setState({...state, expired: e})} />
                                    <div id="floatingInputHelp" class="form-text">Select the activation period when the voucher will expire</div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Active users</label>
                                    <select id="collapsible-state" name="active_enabled" defaultValue={voucher.active_enabled ? 1 : 0} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" onChange={e => {
                                        setState({
                                            ...state,
                                            active_enabled: parseInt(e.target.value)
                                        });
                                    }}>
                                        <option value="0" data-select2-id="limit_usd">All users can activate this voucher</option>
                                        <option value="1" data-select2-id="wager">Only active players can activate this voucher</option>
                                    </select>
                                </div>

                                {state.active_enabled === 1 && (
                                    <>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                                <label class="form-label" for="basic-default-fullname">Deposits amount</label>
                                                <input type="number" class="form-control" name="active_deposits" defaultValue={voucher.active_deposits} placeholder="Enter deposits amount..." required />
                                                <div id="floatingInputHelp" class="form-text">The amount of deposits that the player had to make during the specified period</div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                                <label class="form-label" for="basic-default-fullname">Deposits period</label>
                                                <input type="hidden" name="active_period" value={state.active_period - Date.now()} hidden />
                                                <Picker singleDatePicker={true} startDate={state.active_period} dataKey="active_period" onChange={e => setState({...state, active_period: e})} />
                                                <div id="floatingInputHelp" class="form-text">The period for which the player must make a deposit in order to be active</div>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <hr class="my-4 mx-n4"></hr>
                                <h6>2. Bonus details</h6>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Voucher type</label>
                                    <select id="collapsible-state" name="type" defaultValue={voucher.type} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" onChange={e => {
                                        setState({
                                            ...state,
                                            type: e.target.value,
                                            bonus_type: e.target.value === "no-deposit" ? "amount" : state.bonus_type,
                                            freespins_type: e.target.value === "no-deposit" ? "fixed": state.freespins_type
                                        });
                                    }}>
                                        <option value="no-deposit" data-select2-id="limit_usd">No deposit voucher</option>
                                        <option value="deposit" data-select2-id="wager">Deposit voucher</option>
                                    </select>
                                </div>

                                {state.type === "deposit" && (
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                            <label class="form-label" for="basic-default-fullname">Minimal deposit</label>
                                            <input type="number" name="minimal_deposit" defaultValue={voucher.minimal_deposit} class="form-control" placeholder="100" required />
                                            <div id="floatingInputHelp" class="form-text">The minimum deposit amount to activate the voucher (<code>{`{minimal_deposit}`}</code>)</div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                            <label class="form-label" for="basic-default-fullname">Maximal deposit</label>
                                            <input type="number" name="maximal_deposit" defaultValue={voucher.maximal_deposit} class="form-control" placeholder="0" required />
                                            <div id="floatingInputHelp" class="form-text">The maximum deposit amount to activate the voucher (<code>{`{maximal_deposit}`}</code>)</div>
                                        </div>
                                    </div>
                                )}

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Bonus amount type</label>
                                    <select id="collapsible-state" defaultValue={voucher.bonus_amount_type} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="bonus_amount_type" tabindex="-1" aria-hidden="true" onChange={e => setState({...state, bonus_type: e.target.value})}>
                                        <option value="amount" data-select2-id="9">Amount</option>
                                        {state.type === "deposit" && (
                                            <>
                                                <option value="percent" data-select2-id="9">Percent of deposit</option>
                                                <option value="multiplier" data-select2-id="9">Multiplier</option>
                                            </>
                                        )}
                                    </select>
                                </div>     

                                {state.bonus_type === "amount" && (
                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Bonus amount</label>
                                        <input type="number" class="form-control" name="bonus_amount" defaultValue={voucher.bonus_amount} placeholder="Enter bonus amount..." required />
                                        <div id="floatingInputHelp" class="form-text">The amount that will be given to the player (<code>{`{bonus_amount}`}</code>)</div>
                                    </div>
                                )}

                                {state.bonus_type === "percent" && (
                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Bonus amount (%)</label>
                                        <input type="number" class="form-control" name="bonus_amount" defaultValue={voucher.bonus_amount} placeholder="Enter bonus amount..." required />
                                        <div id="floatingInputHelp" class="form-text">The amount that will be given to the player (<code>{`{bonus_amount}`}</code>)</div>
                                    </div>
                                )}

                                {state.bonus_type === "multiplier" && (
                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Bonus multiplier (X)</label>
                                        <input type="number" class="form-control" name="bonus_amount" defaultValue={voucher.bonus_amount} placeholder="Enter bonus amount..." required />
                                        <div id="floatingInputHelp" class="form-text">The multiplier by which the player's deposit will be multiplied (<code>{`{bonus_multiplier}`}</code>)</div>
                                    </div>
                                )}

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Bonus freespins</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="freespins_enabled" defaultValue={voucher.freespins_enabled ? 1 : 0} tabindex="-1" aria-hidden="true" onChange={e => setState({...state, freespins_enabled: parseInt(e.target.value)})}>
                                        <option value="0" data-select2-id="9">Disabled</option>
                                        <option value="1" data-select2-id="9">Enabled</option>
                                    </select>
                                </div>

                                {state.freespins_enabled === 1 && (
                                    <>
                                        <div class="mb-3">
                                            <label class="form-label" for="basic-default-fullname">Bonus freespins type</label>
                                            <select id="collapsible-state" class="select2 form-select select2-hidden-accessible"  defaultValue={voucher.freespins_type} data-allow-clear="true" data-select2-id="collapsible-state" name="freespins_type" tabindex="-1" aria-hidden="true" onChange={e => setState({...state, freespins_type: e.target.value})}>
                                                <option value="fixed" data-select2-id="9">Fixed count</option>
                                                {state.type === "deposit" && <option value="devider" data-select2-id="9">Deposit amount devider</option>}
                                            </select>
                                        </div>     

                                        {state.freespins_type === "fixed" && (
                                            <div class="mb-3">
                                                <label class="form-label" for="basic-default-fullname">Bonus freespins count</label>
                                                <input type="number" class="form-control" name="freespins_count"  defaultValue={voucher.freespins_count} placeholder="Enter freespins count..." required />
                                                <div id="floatingInputHelp" class="form-text">The number of free spins that the player will receive (<code>{`{bonus_freespins}`}</code>)</div>
                                            </div>
                                        )}

                                        {state.freespins_type === "devider" && (
                                            <>
                                                <div class="mb-3">
                                                    <label class="form-label" for="basic-default-fullname">Bonus freespins devider</label>
                                                    <input type="number" class="form-control" name="freespins_count" defaultValue={voucher.freespins_count} placeholder="Enter freespins count..." required />
                                                    <div id="floatingInputHelp" class="form-text">The divisor is calculated as follows: <b>DEPOSIT AMOUNT / <code>{`{bonus_freespins_devider}`}</code></b> = (<code>{`{bonus_freespins}`}</code>)</div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                                        <label class="form-label" for="basic-default-fullname">Minimal freespins count</label>
                                                        <input type="number" name="freespins_minimal_count" defaultValue={voucher.freespins_minimal_count} class="form-control" placeholder="..." required />
                                                    </div>

                                                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                                        <label class="form-label" for="basic-default-fullname">Maximal freespins count</label>
                                                        <input type="number" name="freespins_maximal_count" defaultValue={voucher.freespins_minimal_count} class="form-control" placeholder="..." required />
                                                        <div id="floatingInputHelp" class="form-text"><code>0</code> - disabled</div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        <div class="mb-3">
                                            <label class="form-label" for="basic-default-fullname">Bonus freespins game</label>
                                            <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="freespins_game_type" defaultValue={voucher.freespins_game_type} tabindex="-1" aria-hidden="true" onChange={e => setState({...state, freespins_game_type: e.target.value})}>
                                                <option value="random" data-select2-id="9">Random</option>
                                                <option value="choose" data-select2-id="9">Choose games</option>
                                            </select>
                                        </div>

                                        {state.freespins_game_type !== "random" && (
                                            <div class="mb-3">
                                                <label class="form-label" for="basic-default-fullname">Select freespins game</label>
                                                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="freespins_game"  defaultValue={voucher.freespins_game} tabindex="-1" aria-hidden="true">
                                                    {games.map(game => {
                                                        return (
                                                            <option value={game.gameId} data-select2-id="9">{game.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <div id="floatingInputHelp" class="form-text">Select a specific game in which the free spins will be played (<code>{`{bonus_freespins_game}`}</code>)</div>
                                            </div>
                                        )}
                                    </>
                                )}

                                {/* {bonusType} */}

                                <hr class="my-4 mx-n4"></hr>
                                <h6>3. Wager condition</h6>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Bonus mode</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="mode" defaultValue={voucher.mode} tabindex="-1" aria-hidden="true">
                                        <option value="bonus" data-select2-id="9">Bonus</option>
                                        <option value="fun" data-select2-id="23">Free Play</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">The mode of your bonus</div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                        <label class="form-label" for="basic-default-fullname">Wagering mode</label>
                                        <select id="collapsible-state" name="wager_type" defaultValue={voucher.wager_type} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                                            <option value="wager" data-select2-id="wager">Wager</option>
                                            <option value="limit_usd" data-select2-id="limit_usd">Redeem (Fixed)</option>
                                            <option value="limit" data-select2-id="limit">Redeem (Multiplier)</option>
                                        </select>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                        <label class="form-label" for="basic-default-fullname">The value of wagering</label>
                                        <input type="number" name="wager_value" defaultValue={voucher.wager_value} class="form-control" placeholder="..." required />
                                    </div>
                                </div>

                                <hr class="my-4 mx-n4"></hr>
                                <h6>4. Games restrictions</h6>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Games restrictions</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="games_restrictions" defaultValue={voucher.games_restrictions} tabindex="-1" aria-hidden="true" onChange={e => setState({...state, games_restrictions: parseInt(e.target.value)})}>
                                        <option value="0" data-select2-id="9">The bonus can be wagered in all games</option>
                                        <option value="1" data-select2-id="9">The bonus can be wagered in certain games</option>
                                    </select>
                                </div>

                                {state.games_restrictions === 1 && (
                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Wagerings games list</label>
                                        <input name="games_restrictions_list" value={JSON.stringify(state.games_restrictions_list)} hidden />
                                        <Tags
                                            dataKey="games_restrictions_list"
                                            whitelist={games.map(game => `${game.name} // ${game.gameId}`)}
                                            enforceWhitelist={true}
                                            autoComplete={true}
                                            onChange={e => {
                                                console.log(e);

                                                e = e.map(name => {
                                                    const game = games.filter(game => game.name === name.split(' // ')[0])[0] || false;
                                                    return game ? game.gameId : null;
                                                }).filter(g => g);

                                                setState({
                                                    ...state,
                                                    games_restrictions_list: e
                                                })
                                            }}
                                            value={state.games_restrictions_list.map(gameId => {
                                                const game = games.filter(game => game.gameId === gameId)[0] || false;
                                                return game ? `${game.name} // ${game.gameId}` : null;
                                            }).filter(g => g)} />
                                        <div id="floatingInputHelp" class="form-text">Select the list of games where the voucher wagering will be available</div>
                                    </div>
                                )}

                                <hr class="my-4 mx-n4"></hr>
                                <h6>5. Bonus access</h6>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Mandatory phone number validation</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="phone_validation" defaultValue={voucher.phone_validation} tabindex="-1" aria-hidden="true">
                                        <option value="0" data-select2-id="23">No</option>
                                        <option value="1" data-select2-id="9">Yes</option>
                                        
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">Does the user have to verify the phone number to activate this bonus</div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Mandatory WhatsApp validation</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="whatsapp_validation" defaultValue={voucher.whatsapp_validation} tabindex="-1" aria-hidden="true">
                                        <option value="0" data-select2-id="23">No</option>
                                        <option value="1" data-select2-id="9">Yes</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">Does the user have to connect WhatsApp to activate this bonus</div>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label" for="basic-default-fullname">Currency</label>
                                    <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="currency" defaultValue={voucher.currency} tabindex="-1" aria-hidden="true">
                                        <option value="RUB" data-select2-id="23">RUB</option>
                                        <option value="USD" data-select2-id="9">USD</option>
                                        <option value="EUR" data-select2-id="23">EUR</option>
                                        <option value="PHP" data-select2-id="9">PHP</option>
                                    </select>
                                    <div id="floatingInputHelp" class="form-text">Select the currency in which the created bonus will be available</div>
                                </div>

                                <button type="submit" class="btn btn-primary" disabled={buttons.vouchers_edit}>Save voucher</button>
                            </form>
                        </div>
                    </div>
            </div>
        </div>
    )
}