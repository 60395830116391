import {useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/settings/finance';
import {saveFinanceSettings} from '../../../../reducers/agent/actions';
import FormUtils from '../../../../utils/form';
import Method from './method';

export default ({currency}) => {
    const {methods: financeMethods, merchant, errors, buttons} = useSelector(Selector),
          methods = financeMethods.filter(method => method.currencies.includes(currency)),
          dispatch = useDispatch();

    if(methods.length < 1)
        return <div className={`alert alert-warning`} role="alert">There are no payment methods for this currency yet</div>

    return (
        <>
            {!merchant.settings.currencys_list.includes(currency) && <div class="alert alert-info" role="alert">Currency <b>{currency}</b> is not enabled in <b>General settings</b></div>}

            {(errors && errors.settings_finance) && (
                <div className={`alert alert-${errors.settings_finance.type}`} role="alert">{errors.settings_finance.message}</div>
            )}

            {methods.map((method, key) => {
                const merchant_method_settings = merchant.settings.finance.filter(m => m.key === method.key && m.currency === currency)[0] || false;
                const method_settings = merchant_method_settings ? merchant_method_settings : {
                    status: 0,
                    deposit_fee_rate: 0.5,
                    cashout_fee_rate: 0.5,
                    minimal_deposit: method.rules[currency].deposit.minimal,
                    maximal_deposit: method.rules[currency].deposit.maximal,
                    minimal_cashout: method.rules[currency].cashout.minimal,
                    maximal_cashout: method.rules[currency].cashout.maximal,
                }

                return <Method method={method} settings={method_settings} currency={currency} buttons={buttons} errors={errors} merchant_method_settings_exist={merchant_method_settings ? true : false} />
            })}
        </>
    )
}