import API from '../../lib/api';
import types from './types';
import Cookies from 'react-cookies';

export const getManager = () => async dispatch => {
    const response = await API({
        path: '/auth/get'
    });
    
    dispatch({
        type: types.loaded,
        payload: response.success ? response : {}
    });
}

export const login = data => async dispatch => {
    dispatch({
        type: types.toggleButton,
        payload: 'login'
    });

    dispatch({
        type: types.error_remove,
        payload: 'auth'
    });

    const response = await API({
        path: '/auth/login',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: 'login'
    });

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "auth",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        Cookies.save('access_token', response.access_token);
        window.location.pathname = '/';
    }
}

export const logout = navigate => dispatch => {
    dispatch({
        type: types.logout
    });

    Cookies.remove('access_token');

    navigate('/login');
}

export const clearErrors = type => {
    return {
        type: types.error_remove
    }
}

export const publishMessage = ({
    key,
    type,
    message
}) => {
    return {
        type: types.error,
        payload: {
            key,
            type,
            message
        }
    }
}

export const saveBonusesSettings = (data) => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "settings_bonuses"
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_bonuses"
    });

    const response = await API({
        path: '/settings/bonuses',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_bonuses"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "settings_bonuses",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.merchant_settings,
            payload: response.settings
        });
        
        dispatch({
            type: types.error,
            payload: {
                key: "settings_bonuses",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const saveCashbackSettings = (data) => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "settings_cashback"
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_cashback"
    });

    const response = await API({
        path: '/settings/cashback',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_cashback"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "settings_cashback",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.merchant_settings,
            payload: response.settings
        });
        
        dispatch({
            type: types.error,
            payload: {
                key: "settings_cashback",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const uploadCashBackImage = (data, data2) => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "settings_cashback"
    });

    dispatch({
        type: types.toggleButton,
        payload: data2.type === 'default' ? 'settings_cashback_image' : 'settings_cashback_image_hh'
    });

    const response = await API({
        path: `/settings/cashback/image/${data2.preference}/${data2.type}`,
        body: data,
        file: true
    });

    dispatch({
        type: types.toggleButton,
        payload: data2.type === 'default' ? 'settings_cashback_image' : 'settings_cashback_image_hh'
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "settings_cashback",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.merchant_settings,
            payload: response.settings
        });

        dispatch({
            type: types.error,
            payload: {
                key: "settings_cashback",
                type: response.message.type,
                message: response.message.message
            }
        });
    }  
}

export const saveGeneralSettings = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "settings_general"
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_general"
    });

    const response = await API({
        path: '/settings/general',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_general"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "settings_general",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.merchant_settings,
            payload: response.settings
        });
        
        dispatch({
            type: types.error,
            payload: {
                key: "settings_general",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const saveReferralSettings = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "settings_referrals"
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_referrals"
    });

    const response = await API({
        path: '/settings/referrals',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_referrals"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "settings_referrals",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.merchant_settings,
            payload: response.settings
        });
        
        dispatch({
            type: types.error,
            payload: {
                key: "settings_referrals",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const saveWinnersSettings = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "settings_winners"
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_winners"
    });

    const response = await API({
        path: '/settings/winners',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_winners"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "settings_winners",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.merchant_settings,
            payload: response.settings
        });
        
        dispatch({
            type: types.error,
            payload: {
                key: "settings_winners",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const saveTitlesSettings = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "settings_titles"
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_titles"
    });

    const response = await API({
        path: '/settings/titles',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_titles"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "settings_titles",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.merchant_settings,
            payload: response.settings
        });
        
        dispatch({
            type: types.error,
            payload: {
                key: "settings_titles",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const saveJackpotsSettings = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "settings_jackpots"
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_jackpots"
    });

    const response = await API({
        path: '/settings/jackpots',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: "settings_jackpots"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "settings_jackpots",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.merchant_settings,
            payload: response.settings
        });
        
        dispatch({
            type: types.error,
            payload: {
                key: "settings_jackpots",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const uploadLogotype = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "settings_general"
    });

    dispatch({
        type: types.toggleButton,
        payload: 'logotype'
    });

    const response = await API({
        path: '/settings/logotype',
        body: data,
        file: true
    });


    dispatch({
        type: types.toggleButton,
        payload: 'logotype'
    });  

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "settings_general",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.merchant_settings,
            payload: response.settings
        });

        dispatch({
            type: types.error,
            payload: {
                key: "settings_general",
                type: response.message.type,
                message: response.message.message
            }
        });
    }  
}