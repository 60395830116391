import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.games.loaded,
    (loaded) => {
        return {
            loaded
        }
    }
)