import {useEffect, createRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/settings/merchant';
import {saveCashbackSettings, publishMessage, uploadCashBackImage} from '../../../../reducers/agent/actions';
import Tagify from '@yaireo/tagify';
import FormUtils from '../../../../utils/form';

export default ({currency}) => {
    const hoursInput = createRef();

    useEffect(() => {
        if(hoursInput.current)
            new Tagify(hoursInput.current, {
                whitelist: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                userInput: true
            });
    }, [currency]);

    const {merchant, errors, buttons} = useSelector(Selector),
          [tab, setTab] = useState(merchant.settings.language_list[0]),
          dispatch = useDispatch();

    const getBonusContent = () => {
        const content = {};
        for(const language of merchant.settings.language_list)
            content[language] = {
                name: merchant.settings.currencys[currency].cashback_content[language].name,
                description: merchant.settings.currencys[currency].cashback_content[language].description,
                name_hh: merchant.settings.currencys[currency].cashback_content[language].name_hh,
                description_hh: merchant.settings.currencys[currency].cashback_content[language].description_hh
            }   

        return content;
    }

    const [bonusContent, setBonusContent] = useState(getBonusContent());

    const cashBackDefaultImageInput = createRef(),
          cashBackDefaultImageForm = createRef();

    const cashBackHappyHourImageInput = createRef(),
          cashBackHappyHourImageForm = createRef();

    return (
        <>
            {!merchant.settings.currencys_list.includes(currency) && <div class="alert alert-info" role="alert">Currency <b>{currency}</b> is not enabled in <b>General settings</b></div>}

            {(errors && errors.settings_cashback) && (
                <div className={`alert alert-${errors.settings_cashback.type}`} role="alert">{errors.settings_cashback.message}</div>
            )}
            <div class="card-body">
            <form
                    encType="multipart/form-data"
                    style={{display: 'none'}}
                    onSubmit={e => {
                        e.preventDefault();

                        if(!buttons.settings_cashback_image_hh)
                            dispatch(uploadCashBackImage(new FormData(e.target), {preference: currency, type: 'hh'}));
                    }}>
                        <input
                            ref={cashBackHappyHourImageInput}
                            style={{display: 'none'}}
                            type="file"
                            accept=".png, .svg, .jpg"
                            name="bonus_image"
                            onChange={e => {
                                const file = e.target.files[0],
                                        size = file.size / (2 ** 20);

                                if(!['image/png', 'image/svg+xml', 'image/jpeg', 'image/jpg'].includes(file.type)) {
                                    window.scrollTo(0, 0);
                                    return dispatch(publishMessage({
                                        key: "settings_cashback",
                                        type: 'warning',
                                        message: 'The logotype must be in .png or .svg format'
                                    }));
                                }

                                if(size > 1) {
                                    window.scrollTo(0, 0);
                                    return dispatch(publishMessage({
                                        key: "settings_cashback",
                                        type: 'warning',
                                        message: 'The maximum file size is 1 MB'
                                    }));
                                }

                                if(cashBackHappyHourImageForm.current)
                                    cashBackHappyHourImageForm.current.click();
                            }}
                            hidden />

                        <button type="submit" ref={cashBackHappyHourImageForm}></button>
                    </form>

                <form
                    encType="multipart/form-data"
                    style={{display: 'none'}}
                    onSubmit={e => {
                        e.preventDefault();

                        if(!buttons.settings_cashback_image)
                            dispatch(uploadCashBackImage(new FormData(e.target), {preference: currency, type: 'default'}));
                    }}>
                        <input
                            ref={cashBackDefaultImageInput}
                            style={{display: 'none'}}
                            type="file"
                            accept=".png, .svg, .jpg"
                            name="bonus_image"
                            onChange={e => {
                                const file = e.target.files[0],
                                        size = file.size / (2 ** 20);

                                if(!['image/png', 'image/svg+xml', 'image/jpeg', 'image/jpg'].includes(file.type))
                                    return dispatch(publishMessage({
                                        key: "settings_general",
                                        type: 'warning',
                                        message: 'The logotype must be in .png or .svg format'
                                    }));

                                if(size > 1)
                                    return dispatch(publishMessage({
                                        key: "settings_general",
                                        type: 'warning',
                                        message: 'The maximum file size is 1 MB'
                                    }));

                                if(cashBackDefaultImageForm.current)
                                    cashBackDefaultImageForm.current.click();
                            }}
                            hidden />

                        <button type="submit" ref={cashBackDefaultImageForm}></button>
                    </form>
                <form onSubmit={e => {
                    e.preventDefault();

                    if(buttons.settings_cashback)
                        return;

                    const data = FormUtils(e);
                    dispatch(saveCashbackSettings(data))
                }}>
                    <input type="hidden" hidden readOnly name="preference" value={currency} />
                    <input name="content" value={JSON.stringify(bonusContent)} hidden />

                    <h6>1. Bonus Content</h6>

                    <div className="row">
                        <div class="col-xl-12">
                            <div class="nav-align-top mb-4">
                                <ul class="nav nav-tabs nav-fill" role="tablist">
                                    {merchant.settings.language_list.map((language, key) => {
                                        let icon = "error-alt",
                                            color = "red";

                                        if(bonusContent[language].name && bonusContent[language].description && bonusContent[language].name_hh && bonusContent[language].description_hh) {
                                            icon = "check";
                                            color = "green";
                                        }

                                        return (
                                            <li class="nav-item" role="presentation" key={key}>
                                                <button type="button" class={`nav-link ${tab === language ? 'active': ''}`} role="tab" data-bs-toggle="tab" data-bs-target="#navs-justified-home" aria-controls="navs-justified-home" aria-selected="false" tabindex="-1" onClick={e => setTab(language)}>
                                                    <i class={`tf-icons bx bx-${icon} me-1`} style={{color}}></i> 
                                                    {language}
                                                </button>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <div class="tab-content" style={{borderTop: "1px solid #d9dee3"}}>
                                    <h6>Customize the bonus for each language version</h6>

                                    <div className={`alert alert-primary`} role="alert">If you do not specify the data in the language version, the bonus will not be displayed.</div>

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Bonus name</label>
                                        <input type="text" class="form-control" placeholder={`Enter bonus ${tab} name...`} defaultValue={bonusContent[tab].name} key={tab} onInput={e => {
                                            setBonusContent({
                                                ...bonusContent,
                                                [tab]: {
                                                    ...bonusContent[tab],
                                                    name: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                }
                                            })
                                        }} required />
                                        <div id="floatingInputHelp" class="form-text">The name of your bonus</div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">Bonus descriptions</label>
                                        <textarea id="basic-default-message" class="form-control" placeholder={`Enter bonus ${tab} descriptions...`} defaultValue={bonusContent[tab].description} key={tab} onInput={e => {
                                            setBonusContent({
                                                ...bonusContent,
                                                [tab]: {
                                                    ...bonusContent[tab],
                                                    description: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                }
                                            })
                                        }} required></textarea>
                                        <div id="floatingInputHelp" class="form-text">
                                            You can add bonus data to the description by using keywords <code>{`{bonus_freespins} / {bonus_amount} / {deposit_amount}`}</code>
                                        </div>
                                    </div>


                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">[HH] Bonus name</label>
                                        <input type="text" class="form-control" placeholder={`Enter [HH] bonus ${tab} name...`} defaultValue={bonusContent[tab].name_hh} key={tab} onInput={e => {
                                            setBonusContent({
                                                ...bonusContent,
                                                [tab]: {
                                                    ...bonusContent[tab],
                                                    name_hh: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                }
                                            }) 
                                        }} required />
                                        <div id="floatingInputHelp" class="form-text">The name of your bonus [Happy Hour]</div>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" for="basic-default-fullname">[HH] Bonus descriptions</label>
                                        <textarea id="basic-default-message" class="form-control" placeholder={`Enter [HH] bonus ${tab} descriptions...`} defaultValue={bonusContent[tab].description_hh} key={tab} onInput={e => {
                                            setBonusContent({
                                                ...bonusContent,
                                                [tab]: {
                                                    ...bonusContent[tab],
                                                    description_hh: e.target.value.replace(/ /gi, '').length > 0 ? e.target.value : null
                                                }
                                            })
                                        }} required></textarea>
                                        <div id="floatingInputHelp" class="form-text">
                                            You can add bonus data to the description by using keywords <code>{`{bonus_freespins} / {bonus_amount} / {deposit_amount}`}</code>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="my-4 mx-n4"></hr>
                    <h6>2. Bonus Images</h6>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Cashback Bonus Image</label>
                        <div class="input-group">

                            <button class="btn btn-outline-success" type="button" id="button-addon1" onClick={e => {
                                if(buttons.settings_cashback_image)
                                    return;

                                if(cashBackDefaultImageInput.current)
                                    cashBackDefaultImageInput.current.click();
                            }}>{buttons.settings_cashback_image ? 'Loading...' : 'Upload'}</button>
                            <button class="btn btn-outline-info" type="button" id="button-addon1" onClick={e => {
                                if(!merchant.settings.general.logotype_path)
                                    return window.alert('Bonus image path not found');

                                window.open(`${window.location.origin}${merchant.settings.currencys[currency].cashback_content[merchant.settings.language_list[0]].image_default}`);
                            }}>Check</button>
                            <input type="text" class="form-control" placeholder="Path to logotype" value={merchant.settings.currencys[currency].cashback_content[merchant.settings.language_list[0]].image_default} aria-label="Example text with button addon" aria-describedby="button-addon1" readOnly disabled />
                        </div>
                        <div id="floatingInputHelp" class="form-text">The path to your logo</div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Cashback Bonus Happy Hour Image</label>
                        <div class="input-group">

                            <button class="btn btn-outline-success" type="button" id="button-addon1" onClick={e => {
                                if(buttons.settings_cashback_image_hh)
                                    return;

                                if(cashBackHappyHourImageInput.current)
                                    cashBackHappyHourImageInput.current.click();
                            }}>{buttons.settings_cashback_image_hh ? 'Loading...' : 'Upload'}</button>
                            <button class="btn btn-outline-info" type="button" id="button-addon1" onClick={e => {
                                if(!merchant.settings.general.logotype_path)
                                    return window.alert('Logotype path not found');

                                window.open(`${window.location.origin}${merchant.settings.currencys[currency].cashback_content[merchant.settings.language_list[0]].image_hh}`);
                            }}>Check</button>
                            <input type="text" class="form-control" placeholder="Path to logotype" value={merchant.settings.currencys[currency].cashback_content[merchant.settings.language_list[0]].image_hh} aria-label="Example text with button addon" aria-describedby="button-addon1" readOnly disabled />
                        </div>
                        <div id="floatingInputHelp" class="form-text">The path to your logo</div>
                    </div>

                    <hr class="my-4 mx-n4"></hr>
                    <h6>3. Bonus Settings</h6>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Cashback Bonus enabled</label>
                        <select id="collapsible-state" name="cashback_active" defaultValue={merchant.settings.currencys[currency].cashback_active ? 1 : 0} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                            <option value="1" data-select2-id="9">Yes</option>
                            <option value="0" data-select2-id="9">No</option>
                        </select>
                        <div id="floatingInputHelp" class="form-text">Upon activation of the Cash back bonus, players will be given a bonus with each deposit</div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Minimal cashback deposit</label>
                            <input type="number" name="cashback_deposit_minimal" defaultValue={merchant.settings.currencys[currency].cashback_deposit_minimal} class="form-control" placeholder="50" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">The minimum deposit amount to receive a Cash Back bonus</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Maximal cashback deposit</label>
                            <input type="number" name="cashback_deposit_maximal" defaultValue={merchant.settings.currencys[currency].cashback_deposit_maximal} class="form-control" placeholder="50" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">The maximum deposit amount to receive a Cash Back bonus (0 - disabled)</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Cashback bonus percent (%)</label>
                            <input type="number" name="cashback_percent_default" defaultValue={merchant.settings.currencys[currency].cashback_percent_default} class="form-control" placeholder="50" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">The percentage of the refunded amount of the deposit amount</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Freespins</label>
                            <input type="number" name="cashback_freespins_default" defaultValue={merchant.settings.currencys[currency].cashback_freespins_default} class="form-control" placeholder="20" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">The free spins division parameter (0 - disabled)</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">[HH] Cashback bonus percent (%)</label>
                            <input type="number" name="cashback_percent_happyhour" defaultValue={merchant.settings.currencys[currency].cashback_percent_happyhour} class="form-control" placeholder="50" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">The percentage of the refunded amount of the deposit amount</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">[HH] Freespins</label>
                            <input type="number" name="cashback_freespins_happyhour" defaultValue={merchant.settings.currencys[currency].cashback_freespins_happyhour} class="form-control" placeholder="20" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">The free spins division parameter (0 - disabled)</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Minimal freespins count</label>
                            <input type="number" name="cashback_freespins_min" defaultValue={merchant.settings.currencys[currency].cashback_freespins_min} class="form-control" placeholder="50" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">The minimum number of free spins in the bonus (0 - disable)</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Maximal freespins count</label>
                            <input type="number" name="cashback_freespins_max" defaultValue={merchant.settings.currencys[currency].cashback_freespins_max} class="form-control" placeholder="50" required  key={currency} />
                            <div id="floatingInputHelp" class="form-text">The maximum number of free spins in the bonus (0 - disable)</div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Cashback max bonus amount</label>
                        <input class="form-control" name="cashback_amount_max" defaultValue={merchant.settings.currencys[currency].cashback_amount_max} key={currency}></input>
                        <div id="floatingInputHelp" class="form-text">Maximum bonus amount (0 - disable)</div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Wagering mode</label>
                            <select id="collapsible-state" name="cashback_wager_type" defaultValue={merchant.settings.currencys[currency].cashback_wager_type} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                                <option value="wager" data-select2-id="wager">Wager</option>
                                <option value="limit_usd" data-select2-id="limit_usd">Redeem (Fixed)</option>
                                <option value="limit" data-select2-id="limit">Redeem (Multiplier)</option>
                            </select>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">The value of wagering</label>
                            <input type="number" name="cashback_wager_value" defaultValue={merchant.settings.currencys[currency].cashback_wager_value} class="form-control" placeholder="5" required  key={currency} />
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Cashback Happy Hours</label>
                        <input class="form-control" name="cashback_hours" defaultValue={merchant.settings.currencys[currency].cashback_hours.join(', ')} ref={hoursInput} key={currency}></input>
                        <div id="floatingInputHelp" class="form-text">Choose a happy hour to boost activity at a certain time</div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Happy Hours offset [UTC]</label>
                        <input type="number" name="cashback_offset" defaultValue={merchant.settings.currencys[currency].cashback_offset} class="form-control" placeholder="-7" required  key={currency} />
                        <div id="floatingInputHelp" class="form-text">Enter a deviation from the UTC time zone so that the happy hours work correctly</div>
                    </div>

                    <button type="submit" class="btn btn-primary" disabled={buttons.settings_cashback}>Save</button>
                </form>
            </div>
        </>
    )
}