import {useEffect} from 'react';
import moment from 'moment-timezone';

export default ({
    singleDatePicker = false,
    timePicker = true,
    placeholder,
    startDate,
    endDate,
    onChange,
    dataKey
}) => {
    useEffect(() => {
        window.$(`#${dataKey}`).daterangepicker({
            onselect: onChange,
            timePicker,
            singleDatePicker,
            startDate: moment(startDate),
            endDate: moment(endDate),
            locale: {
                format: 'YYYY-MM-DD hh:mm A'
            }
        }, function() {
            let data = [
                moment(this.startDate._d).unix() * 1e3,
                moment(this.endDate._d).unix() * 1e3
            ];

            if(singleDatePicker)
                return onChange(data[0]);

            return onChange(data)
        });
    }, []);
    
    return <input id={dataKey} class="form-control" placeholder={placeholder} onInput={onChange} />
}