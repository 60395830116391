import {createSelector} from 'reselect';
import props from './props';

export default createSelector(
    props.agent.loaded,
    props.agent.isAuth,
    (agent_loaded, isAuth) => {
        return {
            loaded: agent_loaded,
            states: {
                manager: agent_loaded
            },
            isAuth
        }
    }
)