import {useEffect, useRef} from 'react';
import Tagify from '@yaireo/tagify';

export default ({
    whitelist,
    value,
    dataKey,
    onChange,
    placeholder,
    userInput = true,
    autoComplete = true,
    delimiters = ",",
    isTextArea = false,
    enforceWhitelist = false,
    maxTags = 200
}) => {
    const tagsRef = useRef();

    useEffect(() => {
        if(tagsRef.current) {
            const tagify = new Tagify(tagsRef.current, {
                whitelist,
                userInput,
                autoComplete,
                delimiters,
                enforceWhitelist,
                maxTags
            });

            tagify.on('change', e => {
                const value = JSON.parse(e.detail.value);
                return onChange(value.map(value => value.value));
            });
        }
    }, []);

    if(isTextArea)
        return <textarea class="form-control"  placeholder={placeholder} value={value.join(',')} ref={tagsRef} key={dataKey}></textarea>;

    return <input class="form-control" placeholder={placeholder} value={value.join(',')} ref={tagsRef} key={dataKey}></input>; 
}