export default {
    loaded: 'bonuses.loaded',
    add: 'bonuses.add',
    remove: 'bonuses.remove',
    update: 'bonuses.update',
    reindex: 'bonuses.reindex',
    error: "bonuses.error",
    error_remove: "bonuses.error.remove",
    toggleButton: "bonuses.toggle.button",
    toggleBonus: "bonuses.toggle.bonus"
}