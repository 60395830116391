import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/loaders/games';
import {loadGames} from '../../reducers/games/actions';
import LoaderPage from '../pages/loader';

export default ({children}) => {
    const {loaded} = useSelector(Selector),
          dispatch = useDispatch();

    useEffect(() => {
        if(!loaded)
            dispatch(loadGames());
    }, [loaded]);

    if(!loaded)
        return <LoaderPage />

    return children;
}