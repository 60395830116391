import {useState} from 'react';
import {useDispatch} from 'react-redux';
import AgentTypes from '../../../../reducers/agent/types';
import API from '../../../../lib/api';
import FormUtils from '../../../../utils/form';

export default ({method, settings, currency, merchant_method_settings_exist}) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        deposit_fee_rate: settings.deposit_fee_rate,
        cashout_fee_rate: settings.cashout_fee_rate,
        error: null,
        button: false
    });

    const toggleButton = () => setState({...state, button: !state.button});
    const setError = error => setState({...state, error});

    const saveMethodSettings = async data => {
        toggleButton();

        const response = await API({
            path: '/settings/methods',
            body: {
                method: method.key,
                currency: currency,
                ...data
            }
        });

        if(!response.success) {
            setError({
                type: 'danger',
                text: response.error
            });
        } else {
            dispatch({
                type: AgentTypes.merchant_settings,
                payload: response.settings
            });

            setError({
                type: 'success',
                text: response.message
            });
        }
    }

    return (
        <div class="card-body">
            <form onSubmit={e => {
                e.preventDefault();

                if(state.button)
                    return;

                const data = FormUtils(e);
                saveMethodSettings(data);
            }}>
                <div class="card mb-3">
                    <h5 class="card-header">{method.name}</h5>
                    <div class="card-body">
                        {(!state.error && !merchant_method_settings_exist) && <div className={`alert alert-warning`} role="alert">You haven't set up this payment method yet</div>}
                        {state.error && <div className={`alert alert-${state.error.type}`} role="alert">{state.error.text}</div>}

                        <div class="mb-3">
                            <label class="form-label" for="basic-default-fullname">Status</label>
                            <select id="collapsible-state" name="status" defaultValue={settings.status} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                                <option value="1" data-select2-id="9">Enabled</option>
                                <option value="0" data-select2-id="9">Disabled</option>
                            </select>
                            <div id="floatingInputHelp" class="form-text">You can disable the payment method if necessary</div>
                        </div>

                        <input type="hidden" name="deposit_fee_rate" defaultValue={state.deposit_fee_rate} style={{display: 'none'}} />
                        <input type="hidden" name="cashout_fee_rate" defaultValue={state.cashout_fee_rate} style={{display: 'none'}} />

                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                <label class="form-label" for="basic-default-fullname">Deposit fee</label>
                                <div>
                                    <label for="formRange1" class="form-label" style={{float: "left"}}>Merchant: <b>{parseFloat((method.comissions.deposit * (1 - state.deposit_fee_rate)).toFixed(2))}%</b></label>
                                    <label for="formRange1" class="form-label" style={{float: "right"}}>Customer: <b>{parseFloat((method.comissions.deposit * state.deposit_fee_rate).toFixed(2))}%</b></label>
                                </div>
                                <input type="range" min={0} max={1} step={0.01} class="form-range" defaultValue={state.deposit_fee_rate} onChange={e => setState({...state, deposit_fee_rate: parseFloat(e.target.value)})} />
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                <label class="form-label" for="basic-default-fullname">Cashout fee</label>
                                <div>
                                    <label for="formRange1" class="form-label" style={{float: "left"}}>Merchant: <b>{parseFloat((method.comissions.cashout * (1 - state.cashout_fee_rate)).toFixed(2))}%</b></label>
                                    <label for="formRange1" class="form-label" style={{float: "right"}}>Customer: <b>{parseFloat((method.comissions.cashout * state.cashout_fee_rate).toFixed(2))}%</b></label>
                                </div>
                                <input type="range" min={0} max={1} step={0.01} class="form-range" defaultValue={state.cashout_fee_rate} onChange={e => setState({...state, cashout_fee_rate: parseFloat(e.target.value)})} />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                <label class="form-label" for="basic-default-fullname">Minimal deposit</label>
                                <input type="number" name="minimal_deposit" defaultValue={settings.minimal_deposit} class="form-control" placeholder="50" required key={currency}/>
                                <div id="floatingInputHelp" class="form-text">Minimal service deposit - <code>{method.rules[currency].deposit.minimal} {currency}</code></div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                <label class="form-label" for="basic-default-fullname">Maximal deposit</label>
                                <input type="number" name="maximal_deposit" defaultValue={settings.maximal_deposit} class="form-control" placeholder="50" required key={currency}/>
                                <div id="floatingInputHelp" class="form-text">Maximal service deposit - <code>{method.rules[currency].deposit.maximal} {currency}</code></div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                <label class="form-label" for="basic-default-fullname">Minimal cashout</label>
                                <input type="number" name="minimal_cashout" defaultValue={settings.minimal_cashout} class="form-control" placeholder="50" required key={currency}/>
                                <div id="floatingInputHelp" class="form-text">Minimal service cashout - <code>{method.rules[currency].cashout.minimal} {currency}</code></div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                <label class="form-label" for="basic-default-fullname">Maximal cashout</label>
                                <input type="number" name="maximal_cashout" defaultValue={settings.maximal_cashout} class="form-control" placeholder="50" required key={currency}/>
                                <div id="floatingInputHelp" class="form-text">Maximal service cashout - <code>{method.rules[currency].cashout.maximal} {currency}</code></div>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary" disabled={state.button}>Save</button>
                    </div>
                </div>
            </form>
        </div>
    )
}