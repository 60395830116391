import {Component} from 'react';
import Navigation from './components/navigation';
import Header from './components/header';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Selector from '../selectors/layout';
import {getManager, clearErrors} from '../reducers/agent/actions';
import Pages from './pages';
import {Navigate, Routes, Route} from 'react-router-dom';
import NavigationUtil from '../utils/navigation';

class Layout extends Component {
    constructor() {
        super();
    }

    render() {
        const {loaded, isAuth} = this.props;

        if(!loaded)
            return <Pages.Loader />;

        if(!isAuth)
            return <Navigate to='/login' />

        return (
            <div class="layout-wrapper layout-content-navbar">
                <div class="layout-container">
                    <Navigation />

                    <div class="layout-page">
                        <Header />

                        <Routes>
                            <Route path='/bonuses*' element={<Pages.Bonuses />} />
                            <Route path='/vouchers*' element={<Pages.Vouchers />} />
                            <Route path='/sliders*' element={<Pages.Sliders />} />

                            <Route exact path='/games' element={<Pages.Games />} />
                            <Route exact path='/settings/titles' element={<Pages.Settings.Titles />} />
                            <Route exact path='/settings/general' element={<Pages.Settings.General />} />
                            <Route exact path='/settings/bonuses' element={<Pages.Settings.Bonuses />} />
                            <Route exact path='/settings/cashback' element={<Pages.Settings.Cashback />} />
                            <Route exact path='/settings/jackpots' element={<Pages.Settings.Jackpots />} />
                            <Route exact path='/settings/winners' element={<Pages.Settings.Winners />} />
                            <Route exact path='/settings/referrals' element={<Pages.Settings.Referrals />} />
                            <Route exact path='/settings/finance' element={<Pages.Settings.Finance />} />

                            {/* <Route exact path='/finance/methods/cashapp' element={<Pages.Finance.Methods.CashApp />} /> */}
                            <Route path='*' element={<Pages.NotFound />} />
                        </Routes>
                    </div>

                </div>

                <div class="layout-overlay layout-menu-toggle animate__animated animate__faster" onClick={NavigationUtil.toggle}></div>
            </div>
        )
    }

    componentDidMount() {
        const {loaded, states, isAuth} = this.props;

        if(!loaded) {
            if(!states.manager)
                this.props.getManager();
        }
    }
}

export default connect(Selector, dispatch => bindActionCreators({
    getManager
}, dispatch)) (Layout);