import agent from './agent';
import games from './games';
import bonuses from './bonuses';
import vouchers from './vouchers';
import sliders from './sliders';

export default {
    agent,
    games,
    bonuses,
    vouchers,
    sliders
}