export default {
    loaded: ({games}) => games.loaded,
    games: ({games}) => games.games,
    patterns: ({games}) => games.patterns,
    errors: {
        all: ({games}) => games.errors
    },
    buttons: {
        all: ({games}) => games.buttons
    }
}