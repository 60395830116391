import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.vouchers.loaded,
    props.games.loaded,
    (vouchers, games) => {
        return {
            vouchers,
            games,
            loaded: vouchers && games
        }
    }
)