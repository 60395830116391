import {useEffect, createRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../../../selectors/pages/settings/merchant';
import {saveWinnersSettings} from '../../../../reducers/agent/actions';
import GamesSelector from '../../../../selectors/pages/settings/games';
import Tagify from '@yaireo/tagify';
import FormUtils from '../../../../utils/form';

export default ({currency}) => {
    const namesTextarea = createRef(),
          gamesTextarea = createRef();

    const {merchant, errors, buttons} = useSelector(Selector),
          {games} = useSelector(GamesSelector),
          dispatch = useDispatch();

    useEffect(() => {
        if(namesTextarea.current) new Tagify(namesTextarea.current, {
            delimiters: ",",
            maxTags: 1e2
        });

        if(gamesTextarea.current) new Tagify(gamesTextarea.current, {
            enforceWhitelist: true,
            delimiters: ",",
            whitelist: games.map(game => game.name),
            maxTags: 2e3,
            autoComplete: true
        });
    });

    return (
        <>
            {!merchant.settings.currencys_list.includes(currency) && <div class="alert alert-info" role="alert">Currency <b>{currency}</b> is not enabled in <b>General settings</b></div>}

            {(errors && errors.settings_winners) && (
                <div className={`alert alert-${errors.settings_winners.type}`} role="alert">{errors.settings_winners.message}</div>
            )}

            <div class="card-body">
                <form onSubmit={e => {
                    e.preventDefault();

                    if(buttons.settings_winners)
                        return;

                    const data = FormUtils(e);
                    dispatch(saveWinnersSettings(data))
                }}>
                    <input type="hidden" hidden readOnly name="preference" value={currency} />

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Real bet publish from</label>
                            <input type="number" name="winners_real_bet_publish" defaultValue={merchant.settings.currencys[currency].winners_real_bet_publish} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">The amount at which the winnings are published</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Show country flag</label>
                            <select id="collapsible-state" name="winners_show_flag" defaultValue={merchant.settings.currencys[currency].winners_show_flag ? 1 : 0} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                                <option value="1" data-select2-id="9">Yes</option>
                                <option value="0" data-select2-id="9">No</option>
                            </select>
                            <div id="floatingInputHelp" class="form-text">Display the flag of the user's country</div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Fake Winners</label>
                        <select id="collapsible-state" name="winners_actions" defaultValue={merchant.settings.currencys[currency].winners_actions ? 1 : 0} class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true" key={currency}>
                            <option value="1" data-select2-id="9">Yes</option>
                            <option value="0" data-select2-id="9">No</option>
                        </select>
                        <div id="floatingInputHelp" class="form-text">After enabling fake winnings, your activity will visually increase</div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Minimal interval (ms)</label>
                            <input type="number" name="winners_interval_from" defaultValue={merchant.settings.currencys[currency].winners_interval_from} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">Minimal interval between actions</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Maximal interval (ms)</label>
                            <input type="number" name="winners_interval_to" defaultValue={merchant.settings.currencys[currency].winners_interval_to} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">Maximal interval between actions</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Minimum win amount</label>
                            <input type="number" name="winners_amount_from" defaultValue={merchant.settings.currencys[currency].winners_amount_from} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">The minimum amount of winnings that will be displayed in the winners' feed</div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                            <label class="form-label" for="basic-default-fullname">Maximum win amount</label>
                            <input type="number" name="winners_amount_to" defaultValue={merchant.settings.currencys[currency].winners_amount_to} class="form-control" placeholder="50" required key={currency}/>
                            <div id="floatingInputHelp" class="form-text">The maximum amount of winnings that will be displayed in the winners' feed</div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Names</label>
                        <textarea class="form-control" name="winners_names" placeholder="Enter name.." defaultValue={merchant.settings.currencys[currency].winners_names.join(', ')} ref={namesTextarea} key={currency}></textarea>
                        <div id="floatingInputHelp" class="form-text">Enter the list of names of the winners</div>
                    </div>

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Games</label>
                        <button type="button" class="btn btn-xs btn-primary" style={{marginLeft: '10px'}} onClick={e => {
                            gamesTextarea.current.value = games.map(game => game.name).join(', ');
                        }}>Select all</button>
                        <button type="button" class="btn btn-xs btn-danger" style={{marginLeft: '10px'}} onClick={e => {
                            gamesTextarea.current.value = '';
                        }}>Clear all</button>
                        <textarea class="form-control" name="winners_games" placeholder="Enter game name..." defaultValue={merchant.settings.currencys[currency].winners_games.map(gameId => {
                            const game = games.filter(game => game.gameId === gameId)[0] || false;
                            return game ? game.name : null
                        }).filter(g => g !== null)} ref={gamesTextarea} key={currency}></textarea>
                        <div id="floatingInputHelp" class="form-text">Enter a list of games to generate fake winnings</div>
                    </div>

                    <button type="submit" class="btn btn-primary" disabled={buttons.settings_winners}>Save</button>
                </form>
            </div>
        </>
    )
}