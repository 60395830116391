import {createSelector} from 'reselect';
import props from '../props';

export default createSelector(
    props.agent.agent,
    (agent) => {
        return {
            agent
        }
    }
)