import types from './types';
import API from '../../lib/api';

export const loadVouchers = () => async dispatch => {
    const response = await API({
        path: '/vouchers/get'
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: response
        });
}

export const toggleVoucher = id => async dispatch => {
    dispatch({
        type: types.toggleVoucher,
        payload: id
    });

    const response = await API({
        path: '/vouchers/toggle',
        body: {
            id
        }
    });

    dispatch({
        type: types.toggleVoucher,
        payload: id
    });

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "vouchers_list",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.update,
            payload: {
                id,
                status: response.nextStatus
            }
        });

        dispatch({
            type: types.error,
            payload: {
                key: "vouchers_list",
                ...response.message
            }
        });
    }
}

export const createVoucher = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "vouchers_create"
    });

    dispatch({
        type: types.toggleButton,
        payload: "vouchers_create"
    });

    const response = await API({
        path: '/vouchers/create',
        body: data,
        file: true
    });

    dispatch({
        type: types.toggleButton,
        payload: "vouchers_create"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "vouchers_create",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.add,
            payload: response.voucher
        });

        dispatch({
            type: types.error,
            payload: {
                key: "vouchers_create",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const uploadVoucherImage = (data) => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "vouchers_edit"
    });

    dispatch({
        type: types.toggleButton,
        payload: 'vouchers_edit'
    });

    const response = await API({
        path: '/vouchers/image',
        body: data,
        file: true
    });


    dispatch({
        type: types.toggleButton,
        payload: 'vouchers_edit'
    });  

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "vouchers_edit",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.update,
            payload: response.voucher
        });

        dispatch({
            type: types.error,
            payload: {
                key: "vouchers_edit",
                type: response.message.type,
                message: response.message.message
            }
        });
    }  
}

export const saveVoucher = data => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: "vouchers_edit"
    });

    dispatch({
        type: types.toggleButton,
        payload: "vouchers_edit"
    });

    const response = await API({
        path: '/vouchers/save',
        body: data
    });

    dispatch({
        type: types.toggleButton,
        payload: "vouchers_edit"
    });

    window.scrollTo(0, 0);

    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "vouchers_edit",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.update,
            payload: response.voucher
        });

        dispatch({
            type: types.error,
            payload: {
                key: "vouchers_edit",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}


export const removeVoucher = voucherId => async dispatch => {
    dispatch({
        type: types.error_remove,
        payload: 'vouchers_list'
    });

    dispatch({
        type: types.toggleVoucher,
        payload: voucherId
    });

    const response = await API({
        path: '/vouchers/remove',
        body: {
            id: voucherId
        }
    });

    dispatch({
        type: types.toggleVoucher,
        payload: voucherId
    });

    window.scrollTo(0, 0);
    
    if(!response.success) {
        dispatch({
            type: types.error,
            payload: {
                key: "vouchers_list",
                type: 'danger',
                message: response.error
            }
        });
    } else {
        dispatch({
            type: types.remove,
            payload: voucherId
        });

        dispatch({
            type: types.error,
            payload: {
                key: "vouchers_list",
                type: response.message.type,
                message: response.message.message
            }
        });
    }
}

export const publishMessage = ({
    key,
    type,
    message
}) => {
    return {
        type: types.error,
        payload: {
            key,
            type,
            message
        }
    }
}

export const clearErrors = () => {
    return {
        type: types.error_remove
    }
}