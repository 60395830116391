import {useSelector, useDispatch} from 'react-redux';
import Selector from '../../selectors/components/header';
import {logout} from '../../reducers/agent/actions';
import NavigationUtil from '../../utils/navigation';
import {useNavigate} from 'react-router-dom';
import Numeric from './numeric';

export default () => {
    const {agent} = useSelector(Selector),
          dispatch = useDispatch(),
          navigate = useNavigate();

    return (
        <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                <a class="nav-item nav-link px-0 me-xl-4" onClick={NavigationUtil.toggle}>
                    <i class="bx bx-menu bx-sm"></i>
                </a>
            </div>
            <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">

                <div class="navbar-nav align-items-center">
                    <div class="nav-item d-flex align-items-center">
                        {/* <i class="bx bx-search fs-4 lh-0"></i> */}
                        {/* <input type="text" class="form-control border-0 shadow-none ps-1 ps-sm-2" placeholder="Search..." aria-label="Search..." /> */}
                    </div>
                </div>

                <ul class="navbar-nav flex-row align-items-center ms-auto">

                    <li class="nav-item lh-1 me-3">
                        <span></span>
                    </li>

                    <li class="nav-item navbar-dropdown dropdown-user dropdown">
                        <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false">
                            <div class="avatar avatar-online">
                                <img src={`${window.location.origin}/assets/${agent.role}.png`} alt={agent.login} class="w-px-40 h-auto rounded-circle" />
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                                <a class="dropdown-item" href="#">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0 me-3">
                                            <div class="avatar avatar-online">
                                                <img src={`${window.location.origin}/assets/${agent.role}.png`} alt={agent.login} class="w-px-40 h-auto rounded-circle" />
                                            </div>
                                        </div>
                                        <div class="flex-grow-1">
                                            <span class="fw-medium d-block">{agent.login}</span>
                                            <small class="text-muted">{agent.role === 'owner' ? 'Owner' : 'Cashier'}</small>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <div class="dropdown-divider"></div>
                            </li>
                            <li>
                                <a class="dropdown-item" href="#">
                                <i class="bx bx-user me-2"></i>
                                <span class="align-middle">My Profile</span>
                                </a>
                            </li>
                            <li>
                                <div class="dropdown-divider"></div>
                            </li>
                            <li>
                                <a class="dropdown-item" href="" onClick={e => {
                                    e.preventDefault();
                                    dispatch(logout(navigate));
                                }}>
                                <i class="bx bx-power-off me-2"></i>
                                <span class="align-middle">Log Out</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>
        </nav>
    )
}