import {useState} from 'react';

export default ({games, bonus}) => {
    const [preset, setPreset] = useState({
        freespins_enabled: bonus.freespins_enabled,
        freespins_game_type: bonus.freespins_game_type,
        bonus_amount_type: bonus.bonus_amount_type,
        freespins_type: bonus.freespins_type || "fixed"
    });

    return (
        <>
            <div class="mb-3">
                <label class="form-label" for="basic-default-fullname">Deposit Include</label>
                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" disabled data-select2-id="collapsible-state" tabindex="-1" aria-hidden="true">
                    <option value="1" data-select2-id="9">Yes</option>
                </select>
                <input name="deposit_include" value={1} readOnly hidden />
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                    <label class="form-label" for="basic-default-fullname">Minimal deposit</label>
                    <input type="number" name="minimal_deposit" defaultValue={bonus.minimal_deposit} class="form-control" placeholder="..." required />
                    <div id="floatingInputHelp" class="form-text">The minimum deposit amount to activate the bonus (<code>{`{minimal_deposit}`}</code>)</div>
                </div>

                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                    <label class="form-label" for="basic-default-fullname">Maximal deposit</label>
                    <input type="number" name="maximal_deposit" defaultValue={bonus.maximal_deposit} class="form-control" placeholder="..." required />
                    <div id="floatingInputHelp" class="form-text">The maximum deposit amount to activate the bonus (<code>{`{maximal_deposit}`}</code>)</div>
                </div>
            </div>

            <div class="mb-3">
                <label class="form-label" for="basic-default-fullname">Bonus amount type</label>
                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" defaultValue={bonus.bonus_amount_type} data-allow-clear="true" data-select2-id="collapsible-state" name="bonus_amount_type" tabindex="-1" aria-hidden="true" onChange={e => setPreset({...preset, bonus_amount_type: e.target.value})}>
                    <option value="amount" data-select2-id="9">Amount</option>
                    <option value="percent" data-select2-id="9">Percent of deposit</option>
                </select>
            </div>            

            {preset.bonus_amount_type === "amount" && (
                <div class="mb-3">
                    <label class="form-label" for="basic-default-fullname">Bonus amount</label>
                    <input type="number" class="form-control" name="bonus_amount" defaultValue={bonus.bonus_amount} placeholder="Enter bonus amount..." required />
                    <div id="floatingInputHelp" class="form-text">The amount that will be given to the player (<code>{`{bonus_amount}`}</code>)</div>
                </div>
            )}

            {preset.bonus_amount_type === "percent" && (
                <div class="mb-3">
                    <label class="form-label" for="basic-default-fullname">Bonus amount (%)</label>
                    <input type="number" class="form-control" name="bonus_amount" defaultValue={bonus.bonus_amount} placeholder="Enter bonus amount..." required />
                    <div id="floatingInputHelp" class="form-text">The amount that will be given to the player (<code>{`{bonus_amount}`}</code>)</div>
                </div>
            )}

            <div class="mb-3">
                <label class="form-label" for="basic-default-fullname">Bonus freespins</label>
                <select id="collapsible-state" class="select2 form-select select2-hidden-accessible"  defaultValue={bonus.freespins_enabled ? 1 : 0} data-allow-clear="true" data-select2-id="collapsible-state" name="freespins_enabled" tabindex="-1" aria-hidden="true" onChange={e => setPreset({...preset, freespins_enabled: parseInt(e.target.value)})}>
                    <option value="0" data-select2-id="9">Disabled</option>
                    <option value="1" data-select2-id="9">Enabled</option>
                </select>
            </div>

            {preset.freespins_enabled && (
                <>
                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Bonus freespins type</label>
                        <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" defaultValue={bonus.freespins_type} data-allow-clear="true" data-select2-id="collapsible-state" name="freespins_type" tabindex="-1" aria-hidden="true" onChange={e => setPreset({...preset, freespins_type: e.target.value})}>
                            <option value="fixed" data-select2-id="9">Fixed count</option>
                            <option value="devider" data-select2-id="9">Deposit amount devider</option>
                        </select>
                    </div>     

                    {preset.freespins_type === "fixed" && (
                        <div class="mb-3">
                            <label class="form-label" for="basic-default-fullname">Bonus freespins count</label>
                            <input type="number" class="form-control" name="freespins_count" defaultValue={bonus.freespins_count} placeholder="Enter freespins count..." required />
                            <div id="floatingInputHelp" class="form-text">The number of free spins that the player will receive (<code>{`{bonus_freespins}`}</code>)</div>
                        </div>
                    )}

                    {preset.freespins_type === "devider" && (
                        <>
                            <div class="mb-3">
                                <label class="form-label" for="basic-default-fullname">Bonus freespins devider</label>
                                <input type="number" class="form-control" name="freespins_count" defaultValue={bonus.freespins_count} placeholder="Enter freespins count..." required />
                                <div id="floatingInputHelp" class="form-text">The divisor is calculated as follows: <b>DEPOSIT AMOUNT / <code>{`{bonus_freespins_devider}`}</code></b> = (<code>{`{bonus_freespins}`}</code>)</div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Minimal freespins count</label>
                                    <input type="number" name="freespins_minimal_count" defaultValue={bonus.freespins_minimal_count} class="form-control" placeholder="..." required />
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                    <label class="form-label" for="basic-default-fullname">Maximal freespins count</label>
                                    <input type="number" name="freespins_maximal_count" defaultValue={bonus.freespins_minimal_count} class="form-control" placeholder="..." required />
                                    <div id="floatingInputHelp" class="form-text"><code>0</code> - disabled</div>
                                </div>
                            </div>
                        </>
                    )}

                    <div class="mb-3">
                        <label class="form-label" for="basic-default-fullname">Bonus freespins game</label>
                        <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" defaultValue={bonus.freespins_game_type} data-allow-clear="true" data-select2-id="collapsible-state" name="freespins_game_type" tabindex="-1" aria-hidden="true" onChange={e => setPreset({...preset, freespins_game_type: e.target.value})}>
                            <option value="random" data-select2-id="9">Random</option>
                            <option value="choose" data-select2-id="9">Choose games</option>
                        </select>
                    </div>

                    {preset.freespins_game_type !== "random" && (
                        <div class="mb-3">
                            <label class="form-label" for="basic-default-fullname">Select freespins game</label>
                            <select id="collapsible-state" class="select2 form-select select2-hidden-accessible" data-allow-clear="true" data-select2-id="collapsible-state" name="freespins_game"  defaultValue={bonus.freespins_game} tabindex="-1" aria-hidden="true">
                                {games.map(game => {
                                    return (
                                        <option value={game.gameId} data-select2-id="9">{game.name}</option>
                                    )
                                })}
                            </select>
                            <div id="floatingInputHelp" class="form-text">Select a specific game in which the free spins will be played (<code>{`{bonus_freespins_game}`}</code>)</div>
                        </div>
                    )}
                </>
            )}

        </>
    )
}